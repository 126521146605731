
import { FSXABaseSection } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'
import { ILink } from '../../../shared/general/interfaces/ILink'
import IEvents from '../../../shared/fsxa/interfaces/IEvents'
import { ITeaserData } from '../../../shared/general/interfaces/ITeaserData'
import { globalLabelAsString } from '../../../shared/general/services/StoreService'
import ISmartSearchHost from '../../../shared/general/interfaces/ISmartSearchHost'
import {
  normalizeTeaserText, searchTeasers, TTeaserSearchResult, TTeaserType,
} from '../../../shared/general/services/SmartSearchTeaserService'
import ITeaser from '../../../shared/fsxa/interfaces/ITeaser'
import mapToResolutions from '../../../shared/general/services/ImageService'
import { formatJoinFromToDate } from '../../../shared/general/services/DateTimeService'
import ITagData from '../../../shared/fsxa/interfaces/ITagData'
import { TRemoteDatasetIndex } from '../../../shared/fsxa/types/TRemoteDataset'
import getOrFetchRemoteDatasets, { getRemoteDatasetsFromStore } from '../../../shared/fsxa/services/RemoteDatasetService'

@Component({ name: 'BaseTeaserEventsSection' })
export default class BaseTeaserEventsSection extends FSXABaseSection<IEvents> {
  protected smartSearchTeasers : ITeaserData[] = []

  async serverPrefetch () {
    await this.fetchRemoteDatasets()
  }

  async mounted () {
    await this.fetchRemoteDatasets()
    if (!this.fixedTeasers.length) this.smartSearchTeasers = await this.loadTeasers()
  }

  private async fetchRemoteDatasets () : Promise<void> {
    await Promise.all([
      getOrFetchRemoteDatasets(this.tagsRemoteDatasetIndex),
      getOrFetchRemoteDatasets(this.globalEventsRemoteDatasetIndex),
      ...this.localEvents.map((event) => getOrFetchRemoteDatasets(event.data?.tt_tags_remote)),
    ])
  }

  private async loadTeasers () : Promise<ITeaserData[]> {
    const smartSearchHost : ISmartSearchHost = {
      host: this.$config.SMART_SEARCH_HOST,
      preparedSearch: this.$config.PREPARED_SEARCH_TEASER_OVERVIEW,
      projectId: this.$config.FSXA_PROJECT_ID,
      remoteProjectId: this.$config.FSXA_REMOTES?.media?.id || '',
    }
    const teaserType : TTeaserType = 'event'
    const pageNumber : number = 1
    const pageSize : number = 3
    // get next upcoming events (which will end soon) and show the ones first which started the earliest
    const result = await searchTeasers(smartSearchHost, {
      locale: this.locale, teaserType, teaserTags: this.teaserTags, pageNumber, pageSize, sort: ['sortDateTo', 'asc'],
    })
    return result.searchResults
      .sort((a, b) => this.sortByDateFrom(a, b))
      .map((searchResult) => normalizeTeaserText(searchResult, (searchRes) => ({ from: searchRes.publishFrom, to: searchRes.publishTo })))
  }

  private sortByDateFrom (a : TTeaserSearchResult, b : TTeaserSearchResult) : number {
    try {
      const dateA = new Date(a.publishFrom)
      const dateB = new Date(b.publishFrom)

      // ascending
      return dateA.getTime() - dateB.getTime()
    } catch (e) {
      return 0
    }
  }

  private sortEventsAscending (a : ITeaser, b : ITeaser) : number {
    if (!a.data.tt_date_from) return -1
    if (!b.data.tt_date_from) return 1
    const dateA = new Date(a.data.tt_date_from)
    const dateB = new Date(b.data.tt_date_from)
    return dateA.getTime() - dateB.getTime()
  }

  private normalizeEvent (teaser : ITeaser) : ITeaserData {
    return {
      image: {
        altText: teaser.data?.tt_teaser_image_alt_text || '',
        title: teaser.data?.tt_teaser_image_alt_text || '',
        resolutions: mapToResolutions(teaser.data?.tt_teaser_image?.resolutions),
      },
      headline: teaser.data?.tt_headline || '',
      date: formatJoinFromToDate({ from: teaser.data?.tt_date_from, to: teaser.data?.tt_date_to }),
      tags: teaser.data?.tt_tags_remote?.value?.length
        ? this.mapTagsRemote(teaser.data?.tt_tags_remote)
        : this.mapTags(teaser.data?.tt_tags),
      location: teaser.data?.tt_location,
      text: teaser.data?.tt_teaser_text,
      link: {
        url: teaser.route || teaser.routes?.[0]?.route,
        iconName: 'chevron-right',
        globalLabelKey: 'specific_event_link_label',
      },
      mediaType: 'image',
    }
  }

  private mapTagsRemote (tags ?: TRemoteDatasetIndex) : string {
    const datasets = getRemoteDatasetsFromStore(tags)
    return this.mapTags(datasets)
  }

  private mapTags (tags ?: ITagData[]) : string {
    if (!tags?.length) return ''
    return tags.map((tag) => tag.data?.tt_name).filter((tag) => !!tag).join(' / ')
  }

  protected get headline () : string | undefined {
    return this.payload.st_headline
  }

  private get localEvents () : ITeaser[] {
    return this.payload.st_local_events || []
  }

  private get globalEventsRemote () : ITeaser[] {
    return getRemoteDatasetsFromStore(this.globalEventsRemoteDatasetIndex)
  }

  private get globalEventsRemoteDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.payload.st_global_events_remote
  }

  private get teaserTags () : string[] {
    return this.tagsRemote.map((tag) => tag.data?.tt_name || '').filter((name) => name.length)
  }

  private get tagsRemote () : ITagData[] {
    return getRemoteDatasetsFromStore(this.tagsRemoteDatasetIndex)
  }

  private get tagsRemoteDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.payload.st_tags_remote
  }

  private get fixedTeasers () : ITeaserData[] {
    return [...this.localEvents, ...this.globalEventsRemote].sort(this.sortEventsAscending).map((event) => this.normalizeEvent(event))
  }

  protected get teasers () : ITeaserData[] {
    return this.fixedTeasers.length ? this.fixedTeasers : this.smartSearchTeasers
  }

  protected get allEventsLink () : ILink {
    return {
      url: this.getUrlByPageId(
        this.globalSettings?.data.ps_country_project_settings?.pt_event_overview_page?.referenceId || '',
      ) ?? '#',
      label: globalLabelAsString('events_link_title_label'),
      iconName: 'chevron-right',
    }
  }
}
