
import { Component } from 'nuxt-property-decorator'
import { Section } from 'fsxa-api'
import { FSXABaseSection } from 'fsxa-pattern-library'
import ILocationData from '../../../shared/fsxa/interfaces/ILocationData'
import IContactElement from '../../../shared/general/interfaces/IContactElement'
import IContact from '../../../shared/general/interfaces/IContact'
import createContactArray, { getAddressString, postalAddress, streetAddress } from '../../../shared/fsxa/services/ContactView'
import getLinkObject from '../../../shared/fsxa/services/LinkService'
import ELinkTarget from '../../../shared/general/enums/ELinkTarget'
import { Logger } from '../../../shared/general/logger/Logger'
import fsxaProxyApiRemote from '../../../shared/fsxa/services/FsxaProxyApiRemote'
import { TRemoteDatasetIndex } from '../../../shared/fsxa/types/TRemoteDataset'
import getOrFetchRemoteDatasets, { getRemoteDatasetsFromStore } from '../../../shared/fsxa/services/RemoteDatasetService'
import { TContactView } from '../../../shared/fsxa/types/TView'
import { TContactElement } from '../../../shared/general/types/TContactElement'

interface IPayload {
  st_headline ?: string
  st_contact_remote ?: TRemoteDatasetIndex
  st_contact_view_remote ?: TRemoteDatasetIndex
  st_column ?: {
    identifier ?: '1' | '3'
  }
  st_show_location_contact ?: boolean
}

enum COLUMN {
  Left = 'left',
  Middle = 'mid',
  Right = 'right'
}

@Component({
  name: 'StContact',
  components: {
    ContactElement: () => import('../../contact/ContactElement.vue'),
  },
})
export default class StContact extends FSXABaseSection<IPayload> {
  private loaded : boolean = false

  private location : ILocationData = this.emptyLocation()

  async serverPrefetch () {
    await this.fetchRemoteDatasets()
  }

  async mounted () {
    // using @Watch breaks the parent page in this case, possibly because it has the same watcher
    this.$watch('$store.state.Locations.savedLocations', this.loadData, { deep: true, immediate: true })
    await this.fetchRemoteDatasets()
  }

  private async fetchRemoteDatasets () : Promise<void> {
    await Promise.all([
      getOrFetchRemoteDatasets(this.contactRemoteDatasetIndex),
      getOrFetchRemoteDatasets(this.contactViewRemoteDatasetIndex),
    ])
  }

  private async loadData () : Promise<void> {
    this.loaded = false
    await this.getLocationData()
    this.loaded = true
  }

  // create an empty object, because reactivity does not work with undefined
  private emptyLocation () : ILocationData {
    return {
      children: [],
      data: {},
      entityType: '',
      id: '',
      locale: '',
      previewId: '',
      route: '',
      routes: [],
      schema: '',
      template: '',
      type: 'Dataset',
    }
  }

  private get contactRemoteDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.payload.st_contact_remote
  }

  private get contactViewRemoteDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.payload.st_contact_view_remote
  }

  private get contactRemote () : ILocationData[] {
    return getRemoteDatasetsFromStore(this.contactRemoteDatasetIndex)
  }

  private get contactViewRemote () : TContactView[] {
    return getRemoteDatasetsFromStore(this.contactViewRemoteDatasetIndex)
  }

  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }

  private get showLocationSpecificData () : boolean {
    return !!this.$store.state.Page.useLocationReference
      && !!this.payload.st_show_location_contact
      && ['ArticlePage', 'TeaserOverview', 'ServicePage', 'ContentPage', 'SharedContentPage'].includes(this.$store.state.Page.pageType || '')
  }

  // has to be a normal function not a getter because otherwise it returns cached values which might be wrong
  private leftCol () : IContactElement[] {
    if (!this.location) return []

    return [
      {
        col: 'left',
        data: this.location?.data?.tt_name,
        type: 'boldText',
      },
      {
        col: 'left',
        data: this.location?.data?.tt_name_2,
        type: 'boldText',
      },
      {
        col: 'left',
        data: streetAddress(this.location),
        type: 'text',
      },
      {
        col: 'left',
        data: this.location?.data?.tt_additional_address,
        type: 'text',
      },
      {
        col: 'left',
        data: postalAddress(this.location),
        type: 'text',
      },
    ]
  }

  // has to be a normal function not a getter because otherwise it returns cached values which might be wrong
  private middleCol () : IContactElement[] {
    if (!this.location) return []

    return [
      {
        col: 'middle',
        data: this.location?.data?.tt_phone_1,
        type: 'phone',
      },
      {
        col: 'middle',
        data: this.location?.data?.tt_phone_2,
        type: 'phone',
      },
      {
        col: 'middle',
        data: this.location?.data?.tt_fax,
        type: 'fax',
      },
      {
        col: 'middle',
        data: this.location?.data?.tt_email,
        type: 'email',
      },
    ]
  }

  // has to be a normal function not a getter because otherwise it returns cached values which might be wrong
  private rightCol () : IContactElement[] {
    if (!this.location) return []

    const addressString = getAddressString(this.location)

    return [
      {
        col: 'middle',
        data: {
          url: `https://www.google.com/maps/dir/?api=1&destination=${encodeURI(addressString)}`,
          globalLabelKey: 'directions_label',
          iconName: 'location-dot',
          withoutIcon: false,
          target: ELinkTarget.Blank,
        },
        type: 'link',

      },
      {
        col: 'middle',
        data: getLinkObject(this.location?.data?.tt_link_website, this.getUrlByPageId),
        type: 'link',
      },
    ]
  }

  private get contactViewConfigLeft () : Section[] {
    return this.contactViewRemote[0]?.data?.tt_config_left || []
  }

  private get contactViewConfigMid () : Section[] {
    return this.contactViewRemote[0]?.data?.tt_config_mid || []
  }

  private get contactViewConfigRight () : Section[] {
    return this.contactViewRemote[0]?.data?.tt_config_right || []
  }

  private getContent (col : COLUMN, locationData : ILocationData) : IContactElement[] {
    let locationViews : Section[] = []
    switch (col) {
      case COLUMN.Left:
        locationViews = this.contactViewConfigLeft
        break
      case COLUMN.Middle:
        locationViews = this.contactViewConfigMid
        break
      case COLUMN.Right:
        locationViews = this.contactViewConfigRight
        break
      default:
        locationViews = []
    }
    if (!locationViews.length || !locationData?.data) return []

    return createContactArray(locationData, locationViews, this.getUrlByPageId, col)
      .filter((el : IContactElement) => el.data && el.type !== 'empty')
  }

  private async getSavedLocation () : Promise<string> {
    if (!this.$store.state.Page.locationType) return ''
    return this.$store.dispatch('Locations/getSavedLocationId', this.$store.state.Page.locationType)
  }

  private async getLocationData () : Promise<void> {
    const savedLocationId = await this.getSavedLocation()
    if (!savedLocationId) {
      this.location = this.emptyLocation()
      return
    }

    try {
      this.location = await fsxaProxyApiRemote.fetchElement({
        id: savedLocationId,
        locale: this.$store.state.Locale.fsxaLocale,
      })
    } catch (e) {
      Logger.warn('Failed to load location data', e)
    }
  }

  private informationData () : IContact[] {
    if (this.showLocationSpecificData && !!this.location?.id) {
      return [{
        contentLeft: this.leftCol(),
        contentMiddle: this.middleCol(),
        contentRight: this.rightCol(),
      }]
    }

    return this.contactRemote.map((information : ILocationData) : IContact => ({
      contentLeft: this.getContent(COLUMN.Left, information),
      contentMiddle: this.getContent(COLUMN.Middle, information),
      contentRight: this.getContent(COLUMN.Right, information),
    }))
  }

  private get locationInformation () : TContactElement {
    return {
      headline: this.payload.st_headline,
      size: this.payload.st_column?.identifier === '1' ? 'small' : 'standard',
      information: this.informationData(),
    }
  }
}
