
import { FSXABaseSection } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'
import { Section } from 'fsxa-api'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import { IGalleryContent } from '../../../shared/general/interfaces/IGalleryContent'
import mapToResolutions from '../../../shared/general/services/ImageService'
import IImage from '../../../shared/general/interfaces/IImage'
import { buildPlayerElements } from '../../../shared/general/services/VideoUrlService'

interface IPayload {
  st_lightbox ?: boolean
  st_teaser_elements ?: Section[]
  st_headline ?: string
}

@Component({
  name: 'StGallery',
  components: {
    BaseGridLayout,
    Gallery: () => import('../../Gallery.vue'),
  },
})

export default class StGallery extends FSXABaseSection<IPayload> {
  get headline () : string {
    return this.payload.st_headline || ''
  }

  get lightbox () : boolean {
    return this.payload.st_lightbox || false
  }

  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }

  get elements () : IGalleryContent[] {
    return this.payload.st_teaser_elements?.map((section : Section) => {
      const image : IImage = {
        altText: section.data?.st_image_alt_text,
        title: section.data?.st_image_alt_text,
        resolutions: mapToResolutions(section.data?.st_image?.resolutions),
      }
      const caption = section.data?.st_image_caption
      const mediaType = section.data?.st_image_video ? 'image' : 'video'

      return {
        title: section.data?.st_headline,
        description: section.data?.st_teaser_text,
        mediaType,
        image,
        caption,
        video: {
          id: section.data?.st_video_id,
          format: section.data?.st_video_format?.key?.toLowerCase() === 'html5' ? 'internal' : 'external',
          htmlPlayerElements: buildPlayerElements({
            mp4: section.data?.st_mp4,
            webm: section.data?.st_webm,
            ogv: section.data?.st_ogv,
          }),
          privacyHash: section.data?.st_vimeo_privacy_hash,
        },
      }
    }) || []
  }
}
