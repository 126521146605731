
import {
  Component, Emit, InjectReactive, Prop, Vue,
} from 'nuxt-property-decorator'
import { TIcon } from '../../shared/general/types/TIcon'
import {
  buttonDefaultStyle, buttonVariantStyles, buttonSizeLarge, buttonSizeSmall,
} from '../../shared/general/services/BaseButtonStyles'
import ELinkTarget from '../../shared/general/enums/ELinkTarget'
import EButtonVariant from '../../shared/general/enums/EButtonVariant'
import { TLink } from '../../shared/general/types/TLink'
import { trackLink } from '../../shared/general/tracking/TrackingService'
import { download } from '../../shared/general/services/FileService'
import { scrollToSection } from '../../shared/general/services/AnchorLinkService'
import { globalLabelAsString } from '../../shared/general/services/StoreService'
import { Logger } from '../../shared/general/logger/Logger'

@Component({
  name: 'BaseLink',
  components: { BaseIcon: () => import('./BaseIcon.vue') },
})
export default class BaseLink extends Vue {
  @InjectReactive({ from: 'triggerRouteChange' }) triggerRouteChange! : Function

  @InjectReactive({ from: 'wMax', default: true }) wMax! : boolean

  @InjectReactive({ from: 'linkColor', default: undefined }) linkColor! : 'darkgreen' | 'black' | 'white' | 'none' | undefined

  @Prop() tag ?: string

  @Prop({ default: false }) reversed! : boolean

  @Prop({ default: false }) withoutIcon! : boolean

  @Prop({ default: 'link' }) type! : TLink

  @Prop({ default: '' }) socialType! : string

  @Prop({ default: 'light' }) iconType! : TIcon

  @Prop({ default: 'h-4' }) iconSize! : string

  @Prop({ default: 'en' }) language! : string

  @Prop({ default: ELinkTarget.Self }) target! : ELinkTarget

  @Prop({ default: EButtonVariant.Default }) buttonVariant! : EButtonVariant

  @Prop({ default: 'large' }) buttonSize! : 'small' | 'large'

  @Prop({ default: false }) buttonInverted! : boolean

  @Prop({ default: false }) overrideStyles! : boolean

  @Prop({ default: false }) onlySlotContent! : boolean

  @Prop({ default: true }) showLabel! : boolean

  @Prop({ default: false }) showInfo! : boolean

  @Prop({ default: 'col' }) displayInfoAs! : 'row' | 'col' | 'row-separate'

  @Prop({ default: false }) infoGroupHover! : boolean

  @Prop({ default: true }) inline! : boolean

  @Prop({ default: false }) noUnderline! : boolean

  @Prop({ default: true }) showDefaultTransition! : boolean

  @Prop({ default: 'darkgreen' }) color! : 'darkgreen' | 'black' | 'white' | 'none'

  @Prop() url ?: string

  @Prop() label ?: string

  @Prop() title ?: string

  @Prop() iconName ?: string

  @Prop() info ?: string

  @Prop() imageSrc ?: string

  @Prop() imageAlt ?: string

  @Prop() globalLabelKey ?: string

  private textElement ?: HTMLElement

  private multiLine : boolean = false

  private lineHeight : number = 0

  private get _color () : 'darkgreen' | 'black' | 'white' | 'none' {
    return this.linkColor || this.color
  }

  private get internal () : boolean {
    return !!this.url?.startsWith('/')
  }

  private get href () : string | undefined {
    if (this.internal) return this.url

    const parameters = Object.entries(this.$store.state.Tracking.parameters)
    if (!parameters.length) return this.url

    const urlParameters = parameters.map(([key, value]) => `${key}=${value}`).join('&')
    return `${this.url}${this.url?.includes('?') ? '&' : '?'}${urlParameters}`
  }

  private get underline () : string {
    if (this.onlySlotContent || this.noUnderline) return ''
    if (this.displayInfoAs === 'row') return 'group-hover:underline'
    if (this.inline) return 'underline'

    return 'group-hover:underline group-focus:underline group-active:underline'
  }

  private get breakableLabel () : string {
    // \u200b creates a hidden breakable space
    // \xad would be a soft hyphen, so it would show a - if the word gets broken down
    const label = this.label?.split('@').join('@\u200b').split('.').join('\u200b.')
    return label || ''
  }

  private get globalLabelValue () : string {
    if (!this.globalLabelKey) return ''

    return globalLabelAsString(this.globalLabelKey)
  }

  private textLinkLabelColor () : string {
    if (this.onlySlotContent) return ''

    const classes = {
      darkgreen: 'text-darkgreen hover:text-darkgreen-600 focus:text-darkgreen-700 active:text-darkgreen-700',
      white: 'text-white',
      black: 'text-black',
      none: '',
    }

    return `${classes[this._color]} outline-none focus-visible:ring focus-visible:ring-black`
  }

  private get hasSlotContent () : boolean {
    return !!this.$slots.default && !!(this.$slots.default[0]?.text || this.$slots.default[0]?.children?.length)
  }

  private get styles () : string {
    if (!this.type) return this.textLinkLabelColor()

    if (this.onlySlotContent) return ''

    const size = this.buttonSize === 'small' ? buttonSizeSmall : buttonSizeLarge

    switch (this.type) {
      case 'button':
        return this.buttonInverted
          ? `${buttonDefaultStyle} ${size} ${buttonVariantStyles[this.buttonVariant].inverted.default}`
          : `${buttonDefaultStyle} ${size} ${buttonVariantStyles[this.buttonVariant].default}`
      case 'social':
        return ''
      default:
        return this.textLinkLabelColor()
    }
  }

  private get componentTag () : string {
    return this.tag ? this.tag : 'a'
  }

  private get image () : string {
    if (!this.type) return ''

    switch (this.type) {
      case 'appStore':
        return `app-store-${this.language}.svg`
      case 'playStore':
        return `google-play-${this.language}.png`
      case 'social':
      case 'social-buttons':
        return this.socialType ? `${this.socialType}.png` : ''
      default:
        return ''
    }
  }

  private get assetFolder () : string {
    switch (this.type) {
      case 'appStore':
        return 'app-buttons'
      case 'playStore':
        return 'app-buttons'
      case 'social':
        return 'social-media'
      case 'social-buttons':
        return 'social-media-buttons'
      default:
        return ''
    }
  }

  private get imageSrcPath () : string {
    if (this.imageSrc) return this.imageSrc

    const { assetFolder, image } = this
    try {
    // eslint-disable-next-line import/no-dynamic-require, global-require
      if (assetFolder && image) return require(`../../static/assets/${assetFolder}/${image}`)
    } catch (error) {
      Logger.error(error)
    }

    return ''
  }

  private track () : void {
    if (this.type === 'download') return
    const data = {
      link_text: this.globalLabelValue || this.label || 'NO_LABEL_GIVEN',
      link_url: this.url || 'NO_URL_GIVEN',
    }

    trackLink(this.$el as HTMLElement, data)
  }

  private setIconPosition () : void {
    if (!this.textElement || !this.textElement.parentElement || this.type === 'button') return
    const parent : HTMLElement = this.textElement.parentElement

    const lineHeight = +window.getComputedStyle(this.textElement).lineHeight.replace('px', '')
    const parentHeight : number = parent.clientHeight - (
      +window.getComputedStyle(parent).paddingTop.replace('px', '') + +window.getComputedStyle(parent).paddingBottom.replace('px', '')
    ) - (
      +window.getComputedStyle(parent).marginTop.replace('px', '') + +window.getComputedStyle(parent).marginBottom.replace('px', '')
    )

    // only use the parent height if there is a sibling which could break into the next line
    const height : number = this.textElement.nextElementSibling ? parentHeight : this.textElement.offsetHeight

    this.lineHeight = height - lineHeight
    this.multiLine = height > lineHeight
  }

  mounted () {
    this.textElement = this.$el.querySelector('.text') as HTMLElement
    this.setIconPosition()
    window.addEventListener('resize', this.setIconPosition)
  }

  @Emit('click')
  private click (event : Event) : this {
    // if hash of url is the same as current (if exists)
    // remove hash and readd it, to trigger scroll again
    scrollToSection(this.url?.substring(this.url.indexOf('#')) || '')

    if (this.componentTag === 'a') {
      this.track()

      if (this.internal) {
        event.preventDefault()
        this.triggerRouteChange({ route: this.url })
      }

      if (this.type === 'download') {
        event.preventDefault()
        download(this.url, this.$el as HTMLElement, this.label)
      }
    }

    return this
  }
}
