
import { Component, InjectReactive, Provide } from 'nuxt-property-decorator'
import { FSXABaseSection } from 'fsxa-pattern-library'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import ITeaserOverviewPayload from '../../../shared/fsxa/interfaces/ITeaserOverviewPayload'
import ITeaser from '../../../shared/fsxa/interfaces/ITeaser'
import { ITeaserData } from '../../../shared/general/interfaces/ITeaserData'
import { globalLabelAsString } from '../../../shared/general/services/StoreService'
import { ISmartSearchMediaHost } from '../../../shared/smartsearch/ExtractTeaserImage'
import { mapToTeaserData, sortDescending } from '../../../shared/fsxa/services/TeaserArticleService'
import getOrFetchRemoteDatasets, { getRemoteDatasetsFromStore } from '../../../shared/fsxa/services/RemoteDatasetService'
import { TRemoteDatasetIndex } from '../../../shared/fsxa/types/TRemoteDataset'
import ITagData from '../../../shared/fsxa/interfaces/ITagData'

interface IPayload extends ITeaserOverviewPayload {
  st_article_selection_type ?: {
    identifier ?: 'manual' | 'dynamic'
  }
  st_global_articles_remote ?: TRemoteDatasetIndex
  st_local_articles ?: ITeaser[]
  st_show_only_dekra_solution_articles ?: boolean
  st_activate_filtering_by_date ?: boolean
  st_tag_list_remote ?: TRemoteDatasetIndex
}

@Component({
  name: 'StTeaserOverviewArticles',
  components: {
    BaseGridLayout,
    BaseTeaser: () => import('../../base/BaseTeaser.vue'),
    TeaserOverview: () => import('../../teaser/overview/TeaserOverview.vue'),
  },
})
export default class StTeaserOverviewArticles extends FSXABaseSection<IPayload> {
  async serverPrefetch () {
    await this.fetchRemoteDatasets()
  }

  async mounted () {
    await this.fetchRemoteDatasets()
  }

  async fetchRemoteDatasets () : Promise<void> {
    await Promise.all([
      ...this.localArticles.map((teaser) => getOrFetchRemoteDatasets(teaser?.data?.tt_tags_remote)),
      getOrFetchRemoteDatasets(this.globalArticlesRemoteDatasetIndex),
      getOrFetchRemoteDatasets(this.tagListRemoteDatasetIndex),
    ])
  }

  @InjectReactive({ from: 'gridSize' }) gridSize! : 'small' | 'wide'

  @Provide() smartSearchMediaHost : ISmartSearchMediaHost = {
    mediaHost: this.$config.SMART_SEARCH_MEDIA_HOST,
    projectId: this.$config.FSXA_PROJECT_ID,
    remotes: this.$config.FSXA_REMOTES,
  }

  private numberOfTeasers : number | undefined = +(this.payload.st_number_of_teasers?.identifier || 0) || undefined

  private showTagFilter : boolean = !!this.payload.st_show_tag_filter

  private activateFilteringByDate : boolean = !!this.payload.st_activate_filtering_by_date

  private showOnlyDekraSolutionArticles : boolean = !!this.payload.st_show_only_dekra_solution_articles

  private isArticlesSelectionTypeManual : boolean = this.payload.st_article_selection_type?.identifier?.includes('manual') || false

  private customParams : [string, string][] = [
    ['facet.filter.FS_template_uid', 'local_data.tt_article'],
    ['facet.filter.FS_template_uid', 'global_data.tt_article'],
    this.showOnlyDekraSolutionArticles ? ['facet.filter.FS_article_mark_as_dekra_solution', 'true'] : ['', ''],
  ]

  private filterQueries : [string, string] = ['FS_publish_from_date_range', '[* TO NOW]']

  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }

  private get resultsLabel () : string {
    return globalLabelAsString('results_label')
  }

  private get articles () : ITeaserData[] {
    if (!this.isArticlesSelectionTypeManual) return []
    const articles : ITeaser[] = [...this.localArticles, ...this.globalArticlesRemote]
    return articles.sort(sortDescending).map((teaser) => mapToTeaserData(teaser))
  }

  private get globalArticlesRemoteDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.payload.st_global_articles_remote
  }

  private get tagListRemoteDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.payload.st_tag_list_remote
  }

  private get globalArticlesRemote () : ITeaser[] {
    return getRemoteDatasetsFromStore(this.globalArticlesRemoteDatasetIndex)
  }

  private get localArticles () : ITeaser[] {
    return this.payload.st_local_articles || []
  }

  private get tags () : string[] {
    return this.tagListRemote.map((tag) => tag.data?.tt_name || '').filter((tagName) => tagName.length)
  }

  private get tagListRemote () : ITagData[] {
    return getRemoteDatasetsFromStore(this.tagListRemoteDatasetIndex)
  }
}
