
import {
  Dataset, RichTextElement, Section,
} from 'fsxa-api'
import { FSXARenderContentElement } from 'fsxa-pattern-library'
import { Component, mixins, Watch } from 'nuxt-property-decorator'
import TheBreadcrumb from '../../layouts/TheBreadcrumb.vue'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import BasePage from '../base/BasePage.vue'
import BaseSharedContentPage from '../base/BaseSharedContentPage.vue'
import BaseToolbarWrapper from '../../base/toolbar/BaseToolbarWrapper.vue'
import TeaserRcWrapper from '../../teaser/rc/TeaserRcWrapper.vue'
import { globalLabel } from '../../../shared/general/services/StoreService'
import BaseHeadline from '../../base/BaseHeadline.vue'
import MessageBar from '../../MessageBar.vue'
import GlobalLabelWrapper from '../../GlobalLabelWrapper.vue'
import getOrFetchRemoteDatasets, { getRemoteDatasetsFromStore } from '../../../shared/fsxa/services/RemoteDatasetService'
import { TRemoteDatasetIndex } from '../../../shared/fsxa/types/TRemoteDataset'

interface ISharedContent extends Dataset {
  data : {
    tt_show_in_this_language ?: boolean
    tt_page_content ?: Section[]
    tt_elements_for_header_content ?: Section[]
  }
}
@Component({
  name: 'PtSharedContentPage',
  components: {
    TheBreadcrumb,
    FSXARenderContentElement,
    BaseGridLayout,
    BaseToolbarWrapper,
    TeaserRcWrapper,
    BaseHeadline,
    MessageBar,
    GlobalLabelWrapper,
  },
})
export default class PtSharedContentPage extends mixins(BasePage, BaseSharedContentPage) {
  async serverPrefetch () {
    await this.fetchRemoteDatasetsSharedContentPage()
  }

  created () {
    this.$store.commit('Page/setPageType', 'SharedContentPage')
  }

  async mounted () {
    await this.fetchRemoteDatasetsSharedContentPage()
  }

  private async fetchRemoteDatasetsSharedContentPage () : Promise<void> {
    await getOrFetchRemoteDatasets(this.sharedContentRemoteDatasetIndex)
  }

  private get sharedContentRemoteDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.data.pt_shared_content_remote
  }

  private get sharedContentRemote () : ISharedContent | undefined {
    return getRemoteDatasetsFromStore(this.sharedContentRemoteDatasetIndex)[0]
  }

  private get showInThisLanguage () : boolean {
    // prevent showing untranslated notification if remote dataset is not yet loaded
    if (!this.sharedContentRemote) return true
    return !!this.sharedContentRemote.data?.tt_show_in_this_language
  }

  private get catalogPageContent () : Section[] {
    return this.sharedContentRemote?.data?.tt_page_content || []
  }

  private get showHeaderContent () : boolean {
    return !!this.headerContent.length
  }

  private get headerContent () : Section[] {
    return this.sharedContentRemote?.data?.tt_elements_for_header_content || []
  }

  private get contentNotTranslatedLabel () : string | RichTextElement[] {
    return globalLabel('content_not_translated_label')
  }

  @Watch('$store.state.Locations.savedLocations', { deep: true, immediate: true })
  private async savedLocationsChange () : Promise<void> {
    this.$store.commit('LocationOnlineAppointment/set', await this.getOnlineAppointmentLink())
    this.$store.commit('ToolbarElements/setOnlineAppointmentLink', await this.getOnlineAppointmentLink(true))
  }
}
