
import {
  Component, Emit, Prop, Vue, Watch,
} from 'nuxt-property-decorator'
import IHeaderNavElement from '../../shared/general/interfaces/IHeaderNavElement'
import BaseGridLayout from './BaseGridLayout.vue'
import BaseIcon from '../base/BaseIcon.vue'
import BaseIconButton from '../base/BaseIconButton.vue'
import BaseButton from '../base/BaseButton.vue'
import BaseNavigationLevel from '../base/BaseNavigationLevel.vue'
import {
  removeLastFromTree,
  setAllChildrenInactiveRecursively,
} from '../../shared/general/services/NavigationMenuService'
import tailwindConfig from '../../tailwind.config.js'
import { globalLabelAsString } from '../../shared/general/services/StoreService'
import isExternalURL from '../../shared/fsxa/services/ExternalLinkService'

@Component({
  name: 'TheNavigation',
  components: {
    BaseIcon,
    BaseIconButton,
    BaseButton,
    BaseGridLayout,
    BaseNavigationLevel,
    GlobalLabelWrapper: () => import('../GlobalLabelWrapper.vue'),
  },
})
export default class TheNavigation extends Vue {
  @Prop() entry ?: IHeaderNavElement

  public tree : IHeaderNavElement[] = []

  private current : number = 0

  private actualCurrent : number = 0

  private base : number = 0

  private resizeObserver : ResizeObserver | null = null

  private widths = {
    md: +tailwindConfig.theme.screens.md.replace('px', ''),
  }

  private get backLabel () : string {
    return globalLabelAsString('prev_date_label')
  }

  private get showBack () : string {
    if (this.tree.length > 2) return 'scale-100 md:scale-0'
    if (this.tree.length > 1) return 'scale-100 sm:scale-0'
    return 'scale-0'
  }

  created () {
    this.$nextTick(() => {
      const container = this.$refs.container as HTMLElement
      if (!container) return

      this.resizeObserver = new ResizeObserver(() => {
        const levels = this.$refs.navLevel as BaseNavigationLevel | BaseNavigationLevel[]
        const level = Array.isArray(levels) ? levels : [levels]
        this.base = level[0]?.$el.getBoundingClientRect().width ?? 0
        if (window.innerWidth < this.widths.md) {
          this.current = this.actualCurrent
        } else {
          this.current = 0
        }
      })
      this.resizeObserver.observe(container)
    })
  }

  reset () {
    this.current = 0
    this.actualCurrent = 0
    this.base = 0
  }

  private goBack () : void {
    if (this.current > 0) {
      this.current -= 1
    }
    const newTree = removeLastFromTree(this.tree)
    newTree.at(-1)?.children?.forEach(setAllChildrenInactiveRecursively)
    this.tree = newTree
  }

  private moveNavigation (index : number) : void {
    let fakeIndex = index

    if (window.innerWidth >= this.widths.md) {
      fakeIndex = Math.max(index - 1, 0)
    }

    this.actualCurrent = index
    this.current = fakeIndex
  }

  private navigate (navElement : IHeaderNavElement) : void {
    if (isExternalURL(navElement.path)) return

    this.$router.push(navElement.path)
    const [first] = this.tree
    first.isActive = false
    first.children?.forEach((child) => {
      child.isActive = false
    })
    this.tree = [first]
    this.close()
  }

  private navigateOrOpenChild (navElement : IHeaderNavElement, index : number) : void {
    if (!navElement.children?.length) {
      this.navigate(navElement)
      return
    }
    const navElementReload = { ...navElement, isActive: true }
    const treeReload = [...this.tree]
    treeReload.length = index + 1

    // Vue does not recognize array object mutations as a change to update UI
    // so we have to replace nearly everything
    // if you someday stumble over this and know a better way, please just do it
    const treeChildren = [...(treeReload[index].children || [])]
    const childIndex = treeChildren.findIndex((treeChild) => treeChild.key === navElementReload.key)
    treeChildren.forEach(setAllChildrenInactiveRecursively)
    treeChildren[childIndex] = navElementReload
    treeReload[index].children = treeChildren
    treeReload.push(navElementReload)
    this.tree = treeReload
    this.moveNavigation(index)
  }

  @Watch('entry')
  private onEntryChange (newEntry : IHeaderNavElement) : void {
    this.reset()
    this.tree = newEntry ? [newEntry] : []
  }

  @Emit('close')
  private close () : boolean {
    return true
  }
}
