
import { File, Image } from 'fsxa-api'
import { FSXABaseSection } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import IImage from '../../../shared/general/interfaces/IImage'
import mapToResolutions from '../../../shared/general/services/ImageService'
import IVideo from '../../../shared/general/interfaces/IVideo'
import { buildPlayerElements } from '../../../shared/general/services/VideoUrlService'
import IImageVideoContent from '../../../shared/general/interfaces/IImageVideoContent'
import { openLightbox } from '../../../shared/general/services/LightboxService'

interface IPayload {
  st_alt_text ?: string
  st_title_text ?: string
  st_image_video_caption ?: string
  st_video_format ?: { key ?: string }
  st_video_id ?: string
  st_vimeo_privacy_hash ?: string
  st_mp4 ?: File
  st_ogv ?: File
  st_webm ?: File
  st_image ?: Image
  st_toggle_image_video ?: boolean
  st_zoom_image_active ?: boolean
  st_zoom_image ?: Image
  st_zoom_image_alt_text ?: string
}

@Component({
  name: 'StImageVideo',
  components: {
    BaseGridLayout,
    BaseImageVideo: () => import('../../ImageVideo/BaseImageVideo.vue'),
  },
})
export default class StImageVideo extends FSXABaseSection<IPayload> {
  private get caption () : string {
    return this.payload.st_image_video_caption ?? ''
  }

  private get lightbox () : boolean {
    const fallbackTo = <T>(fallback : T, val ?: T | null) : T => (val !== undefined && val !== null ? val : fallback)
    const toggleImage = fallbackTo(true, this.payload.st_toggle_image_video)
    const showZoomImage = fallbackTo(false, this.payload.st_zoom_image_active)
    return toggleImage ? showZoomImage : true
  }

  private get imageOrVideo () : 'image' | 'video' {
    return this.payload.st_toggle_image_video ? 'image' : 'video'
  }

  private get video () : IVideo {
    return {
      id: this.payload.st_video_id ?? '',
      format: this.payload.st_video_format?.key === 'html5' ? 'internal' : 'external',
      htmlPlayerElements: buildPlayerElements({
        mp4: this.payload.st_mp4,
        webm: this.payload.st_webm,
        ogv: this.payload.st_ogv,
      }),
      privacyHash: this.payload?.st_vimeo_privacy_hash,
    }
  }

  private get lightboxImage () : IImage | undefined {
    const resolutions = mapToResolutions(this.payload.st_zoom_image?.resolutions)
    return resolutions.length ? {
      altText: this.payload.st_zoom_image_alt_text ?? '',
      title: this.payload.st_zoom_image_alt_text ?? '',
      resolutions,
    } : undefined
  }

  private get image () : IImage {
    return {
      altText: this.payload.st_alt_text ?? '',
      title: this.payload.st_alt_text ?? '',
      resolutions: mapToResolutions(this.payload.st_image?.resolutions),
    }
  }

  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }

  private showLightbox (content : IImageVideoContent) : void {
    if (!this.lightbox && this.imageOrVideo !== 'video') return
    openLightbox(content)
  }
}
