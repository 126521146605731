
import { FSXABaseRichTextElement } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'
import BaseHeadline, { IHeadlineData } from '../../base/BaseHeadline.vue'

@Component({
  name: 'Block',
  components: { BaseHeadline },
})
export default class Block extends FSXABaseRichTextElement {
  private get showAsHeadline () : boolean {
    return this.data['data-fs-style'] !== 'format.strong'
  }

  private get headlineProps () : IHeadlineData {
    return {
      isSemantic: ['format.h2', 'format.h3'].includes(this.data['data-fs-style']),
      type: this.data['data-fs-style'] === 'format.h2' ? 'h2' : 'h3',
    }
  }
}
