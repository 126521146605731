
import { FSXABaseSection } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'
import mapToResolutions from '../../../shared/general/services/ImageService'
import IImage from '../../../shared/general/interfaces/IImage'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import BasePicture from '../../base/BasePicture.vue'
import BaseHeadline from '../../base/BaseHeadline.vue'
import { globalLabelAsString } from '../../../shared/general/services/StoreService'

@Component({
  name: 'StLocationInformation',
  components: {
    BaseGridLayout,
    BasePicture,
    BaseHeadline,
  },
})
export default class StLocationInformation extends FSXABaseSection {
  private get locationImage () : string {
    return this.$store.state.LocationInformation.locationImage
  }

  private get specialServicesList () : string[] {
    return this.$store.state.LocationInformation.specialServicesList
  }

  private get headline () : string {
    return globalLabelAsString('location_information_headline')
  }

  private get alt () : string {
    return this.globalSettings?.data.ps_country_project_settings.pt_location_information_image_alt_text || ''
  }

  private get image () : IImage {
    return {
      altText: this.globalSettings?.data.ps_country_project_settings.pt_location_information_image_alt_text,
      title: this.globalSettings?.data.ps_country_project_settings.pt_location_information_image_alt_text,
      resolutions: mapToResolutions(this.globalSettings?.data.ps_country_project_settings.pt_location_information_image.resolutions),
    }
  }
}
