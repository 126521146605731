
import { FSXABaseLayout } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'
import { Image, RichTextElement } from 'fsxa-api'
import ILocationData, { IOpeningHourData, IOpeningHoursAreaData } from '../../../shared/fsxa/interfaces/ILocationData'
import ILinkData from '../../../shared/fsxa/interfaces/ILinkData'
import IImage from '../../../shared/general/interfaces/IImage'
import ILocationTeaser from '../../../shared/general/interfaces/ILocationTeaser'
import formatPostCodeAddress from '../../../shared/general/services/location/LocationPostCodeViewOrderService'
import formatStreetAddress from '../../../shared/general/services/location/LocationStreetViewOrderService'
import {
  addLinkPrefix,
  contactOf,
  ILocationContactData,
} from '../../../shared/general/services/location/LocationContactService'
import ILocationContactInformationStructuredData from '../../../shared/ILocationContactInformationStructuredData'
import mapToResolutions from '../../../shared/general/services/ImageService'
import ILocationOpeningHoursStructuredData, {
  ILocationOpeningHoursSpecificationStructuredData,
} from '../../../shared/ILocationOpeningHoursStructuredData'
import { IOpeningHoursAreas } from '../../../shared/fsxa/interfaces/IOpeningHoursAreas'
import getLinkObject from '../../../shared/fsxa/services/LinkService'
import ELinkTarget from '../../../shared/general/enums/ELinkTarget'
import { ILink } from '../../../shared/general/interfaces/ILink'
import { globalLabel, globalLabelAsString } from '../../../shared/general/services/StoreService'
import { formatJoinFromToDate, isNowDateInDateRange } from '../../../shared/general/services/DateTimeService'
import { TRemoteDatasetIndex } from '../../../shared/fsxa/types/TRemoteDataset'
import getOrFetchRemoteDatasets, { getRemoteDatasetsFromStore } from '../../../shared/fsxa/services/RemoteDatasetService'
import IReference from '../../../shared/fsxa/interfaces/IReference'

interface ILocationNews {
  data ?: {
    st_headline ?: string
    st_text ?: string
    st_fontawesome_icon_remote ?: TRemoteDatasetIndex
    st_link ?: ILinkData
  }
}

interface IPayload {
  pt_location_remote ?: TRemoteDatasetIndex
  pt_location_stage_img ?: Image
  pt_location_stage_img_alt_text ?: string
  pt_news ?: ILocationNews[]
  pt_show_next_locations ?: boolean
  pt_online_appointment_teaser_headline ?: string
  pt_online_appointment_teaser_text ?: string
  pt_show_location_news_from_country_project_settings ?: boolean
  tt_current_information_date_from ?: string
  tt_current_information_date_to ?: string
}

@Component({ name: 'BaseLocationPage' })
export default class BaseLocationPage extends FSXABaseLayout<IPayload> {
  private windowLocationHref : string = ''

  async serverPrefetch () {
    await this.fetchRemoteDatasetsLocationPage()
  }

  created () {
    this.$store.commit('Page/setPageType', 'LocationPage')
  }

  async mounted () {
    this.windowLocationHref = window.location.href
    await this.fetchRemoteDatasetsLocationPage()

    this.setLocationInformation()
    this.setOnlineAppointmentLink()
    await this.showMessageIfLocationIsAlreadySaved()
  }

  private async fetchRemoteDatasetsLocationPage () : Promise<void> {
    await Promise.all([
      getOrFetchRemoteDatasets(this.locationRemoteDatasetIndex),
      ...this.locationStageTeasers.map((teaser) => getOrFetchRemoteDatasets(teaser.iconRemote)),
    ])
  }

  private get locationRemoteDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.data.pt_location_remote
  }

  protected get locationRemote () : ILocationData | undefined {
    return getRemoteDatasetsFromStore(this.locationRemoteDatasetIndex)[0]
  }

  private get stageTeasers () : ILocationTeaser[] {
    const iconsRemote = this.locationStageTeasers
      .map((teaser) => getRemoteDatasetsFromStore(teaser.iconRemote))
      .map((dataset) => dataset?.[0]?.data?.tt_fontawesome_v6_id || '')

    return this.locationStageTeasers.map((teaser, index) => ({ ...teaser, icon: teaser.icon || iconsRemote[index] }))
  }

  protected get locationId () : string {
    return this.locationRemote?.id || ''
  }

  protected get locationTypes () : string[] {
    return this.locationRemote?.data?.tt_location_types?.map((locationType) => locationType?.data?.tt_key || '').filter((e) => !!e) || []
  }

  public get payload () {
    return {
      pageTitle: this.pageTitle,
      stageImage: this.stageImage,
      stageTextContent: this.stageTextContent,
      stageTeasers: this.stageTeasers,
      showNextLocations: this.showNextLocations,
      nextLocationsHeadline: this.nextLocationsHeadline,
      nextLocationsLink: this.nextLocationsLink,
      contactInformation: this.contactInformation,
      openingHoursAreas: this.openingHoursAreas,
      structuredData: this.openingHoursStructuredData,
    }
  }

  private get pageUrl () : string {
    return this.windowLocationHref
  }

  private get postalAddress () : string {
    const postCodeViewOrder = this.locationRemote?.data.tt_country?.data?.tt_post_code_view_order?.identifier ?? '1'
    const { tt_city: city, tt_zip_code: zipCode, tt_state: state } = this.locationRemote?.data ?? {}
    return formatPostCodeAddress({ city, zipCode, state }, postCodeViewOrder).join(' ')
  }

  private get streetAddress () : string {
    const streetOrder = this.locationRemote?.data?.tt_country?.data?.tt_street_view_order?.identifier ?? '1'
    const { tt_street: street, tt_house_number: houseNumber } = this.locationRemote?.data ?? {}
    return formatStreetAddress({ street, houseNumber }, streetOrder).join(' ')
  }

  private get contactInformationAddress () : string[] {
    const additionalAddress = this.locationRemote?.data.tt_additional_address
    const isString = (value ?: string | null) : value is string => (!!value)
    return [this.streetAddress, additionalAddress, this.postalAddress].filter(isString)
  }

  private get contactInformationCurrentInformation () : RichTextElement[] | undefined {
    const {
      tt_current_information_date_from: dateFrom,
      tt_current_information_date_to: dateTo,
    } = this.locationRemote?.data ?? {}

    if (!isNowDateInDateRange(dateFrom, dateTo)) return []
    return this.locationRemote?.data.tt_current_information
  }

  private get locationContactDetailsRouteDescription () : string {
    return globalLabelAsString('location_contact_details_route_description')
  }

  private get contactInformationContactData () : ILocationContactData[] {
    const data : ILocationContactData[] = []
    const { tt_phone_1: phoneRaw, tt_fax: fax, tt_email: emailRaw } = this.locationRemote?.data ?? {}

    const phone = addLinkPrefix('phone', phoneRaw || '')
    const email = addLinkPrefix('mail', emailRaw || '')

    if (emailRaw) data.push(contactOf('BaseLink', 'envelope', emailRaw, email))
    if (phoneRaw) data.push(contactOf('BaseLink', 'phone', phoneRaw, phone))
    if (fax) data.push(contactOf('BaseIconText', 'fax', fax))
    return data
  }

  private latLngFallbackEmptyString (value ?: number | null) : string {
    // due to 0 being valid
    return (value !== undefined && value !== null) ? `${value}` : ''
  }

  private get contactInformationStructuredData () : ILocationContactInformationStructuredData {
    const { data } = this.locationRemote ?? {}
    const [original] = mapToResolutions(data?.tt_image?.resolutions ?? {})
    const fallbackEmptyStr = (val ?: string | null) => (val ?? '')
    return {
      '@context': 'https://schema.org',
      '@type': 'ProfessionalService',
      image: [fallbackEmptyStr(original?.url)],
      '@id': this.pageUrl,
      name: fallbackEmptyStr(data?.tt_name),
      address: {
        '@type': 'PostalAddress',
        streetAddress: `${fallbackEmptyStr(data?.tt_street)} ${fallbackEmptyStr(data?.tt_house_number)}`.trim(),
        addressLocality: fallbackEmptyStr(data?.tt_city),
        addressRegion: data?.tt_state,
        postalCode: fallbackEmptyStr(data?.tt_zip_code),
        addressCountry: fallbackEmptyStr(data?.tt_country?.data?.tt_name),
      },
      geo: {
        '@type': 'GeoCoordinates',
        latitude: this.latLngFallbackEmptyString(data?.tt_latitude),
        longitude: this.latLngFallbackEmptyString(data?.tt_longitude),
      },
      url: this.pageUrl,
      telephone: fallbackEmptyStr(data?.tt_phone_1),
    }
  }

  private get contactInformation () {
    const { tt_latitude: lat, tt_longitude: lng } = this.locationRemote?.data ?? {}
    return {
      address: this.contactInformationAddress,
      currentInformation: this.contactInformationCurrentInformation,
      routeLink: this.createLocationRouteLink(
        this.latLngFallbackEmptyString(lat),
        this.latLngFallbackEmptyString(lng),
        this.locationContactDetailsRouteDescription,
      ),
      contactData: this.contactInformationContactData,
      structuredData: this.contactInformationStructuredData,
    }
  }

  public get pageTitle () : string {
    const { tt_city: city, tt_name: name } = this.locationRemote?.data ?? {}
    const toName = (val ?: string) => (val ? ` - ${val}` : '')
    return `DEKRA${toName(city)}${toName(name)}`
  }

  private get stageImage () : IImage {
    const { ps_country_project_settings: cps } = this.globalSettings?.data ?? {}
    const image : Image | null | undefined = this.data.pt_location_stage_img ?? cps?.pt_location_stage_img
    return {
      resolutions: mapToResolutions(image?.resolutions),
      altText: this.data.pt_location_stage_img_alt_text ?? cps?.pt_location_stage_img_alt_text ?? '',
      title: this.data.pt_location_stage_img_alt_text ?? cps?.pt_location_stage_img_alt_text ?? '',
    }
  }

  private get stageTextContent () : { headlines : string[], subHeadline : string } {
    const { tt_name: name, tt_name_2: name2, tt_location_types: locationTypes } = this.locationRemote?.data || {}
    return {
      headlines: [name, name2].filter(Boolean).map((el) => `${el}`),
      subHeadline: locationTypes?.map((locationType) => locationType.data?.tt_name).join(' / ') || '',
    }
  }

  private get globalLabelTeaserHeadline () : string {
    return globalLabelAsString('global_online_appointment_teaser_headline')
  }

  private get globalLabelTeaserText () : string {
    return globalLabelAsString('global_online_appointment_teaser_text')
  }

  private get stageAppointmentTeaser () : ILocationTeaser | undefined {
    const {
      pt_online_appointment_teaser_headline: teaserHeadline,
      pt_online_appointment_teaser_text: teaserText,
    } = this.data ?? {}
    const { tt_link_online_appointment: linkOnlineAppointment } = this.locationRemote?.data ?? {}
    const {
      pt_location_online_appointment_cta_teaser_headline: cpsTeaserHeadline,
      pt_location_online_appointment_cta_teaser_text: cpsTeaserText,
    } = this.globalSettings?.data.ps_country_project_settings ?? {}

    if (linkOnlineAppointment) {
      return {
        title: teaserHeadline || cpsTeaserHeadline || this.globalLabelTeaserHeadline,
        content: teaserText || cpsTeaserText || this.globalLabelTeaserText,
        icon: 'calendar-days',
        link: getLinkObject(linkOnlineAppointment, this.getUrlByPageId),
        linkTitle: linkOnlineAppointment.data?.lt_title_text ?? undefined,
        target: linkOnlineAppointment.data?.lt_target ? ELinkTarget.Blank : ELinkTarget.Self,
      }
    }
    return undefined
  }

  private get locationStageTeasers () : ILocationTeaser[] {
    const teasers : ILocationTeaser[] = []
    const pushTeaser = (teaser : ILocationNews) => {
      teasers.push({
        title: teaser.data?.st_headline || '',
        content: teaser.data?.st_text || '',
        icon: '', // will be set after iconRemote was resolved (async)
        iconRemote: teaser.data?.st_fontawesome_icon_remote,
        link: getLinkObject(teaser.data?.st_link, this.getUrlByPageId),
        linkTitle: teaser.data?.st_link?.data?.lt_title_text ?? undefined,
        target: teaser.data?.st_link?.data?.lt_target ? ELinkTarget.Blank : ELinkTarget.Self,
      })
    }

    if (this.stageAppointmentTeaser) teasers.push(this.stageAppointmentTeaser)

    this.data?.pt_news?.forEach((teaser : ILocationNews) => pushTeaser(teaser))

    if (this.data.pt_show_location_news_from_country_project_settings) {
      (this.globalSettings?.data.ps_country_project_settings?.pt_location_news as ILocationNews[])?.forEach((teaser) => pushTeaser(teaser))
    }

    return teasers
  }

  private get showNextLocations () : boolean {
    return !!this.data.pt_show_next_locations
  }

  private get nextLocationsHeadline () : string | undefined {
    return globalLabelAsString('location_next_locations_headline')
  }

  private get nextLocationsLink () : string | undefined {
    const { referenceId } = this.globalSettings?.data.ps_country_project_settings?.pt_location_search_page ?? {}
    if (!referenceId) return undefined
    return this.getUrlByPageId(referenceId) ?? undefined
  }

  private get openingHoursStructuredData () : ILocationOpeningHoursStructuredData | undefined {
    const openingHoursAreas = this.locationRemote?.data?.tt_opening_hours_areas || []
    if (!openingHoursAreas.length) return undefined

    const formatAsDateHHMM = (date ?: string) => (date
      ? formatJoinFromToDate({ from: date, dateOptions: { timeStyle: 'short' } }) : '')

    const specification = (openingHoursArea : IOpeningHoursAreaData) :
      ILocationOpeningHoursSpecificationStructuredData[] => {
      const openingHours : IOpeningHourData[] = openingHoursArea.data?.st_opening_hours || []
      return openingHours.map((openingHour) : ILocationOpeningHoursSpecificationStructuredData => ({
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: openingHour.data?.st_weekdays?.map((day) => day?.value) || [],
        opens: formatAsDateHHMM(openingHour.data?.st_from),
        closes: formatAsDateHHMM(openingHour.data?.st_to),
      }))
    }

    const departmentSpecification : {
      '@type' : string
      openingHoursSpecification : ILocationOpeningHoursSpecificationStructuredData[]
    }[] = openingHoursAreas.map((openingHoursArea) => ({
      '@type': openingHoursArea.data?.st_headline || '',
      openingHoursSpecification: specification(openingHoursArea),
    }))

    return openingHoursAreas.length > 1
      ? { '@id': this.pageUrl, department: departmentSpecification }
      : { '@id': this.pageUrl, openingHoursSpecification: openingHoursAreas.flatMap(specification) }
  }

  private get openingHoursAreas () : IOpeningHoursAreas[] {
    const openingHoursAreas : IOpeningHoursAreaData[] = this.locationRemote?.data?.tt_opening_hours_areas
    || []
    return openingHoursAreas.map((openingHoursArea : IOpeningHoursAreaData) : IOpeningHoursAreas => ({
      headline: openingHoursArea?.data?.st_headline,
      hintTextFormatted: openingHoursArea?.data?.st_hint_text_formatted || [],
      openingHours: openingHoursArea,
    }))
  }

  private createLocationRouteLink (lat : string, lng : string, label ?: string) : ILink {
    const googleMapsApiKey : boolean = !!this.globalSettings?.data.ps_google_maps_api_key
    const baiduMapsApiKey : boolean = !!this.globalSettings?.data.ps_baidu_maps_api_key
    const map : string = this.globalSettings?.data.ps_maps?.identifier ?? 'unknown'
    let url : string | undefined
    if (googleMapsApiKey && 'google_maps'.includes(map)) {
      url = `https://www.google.com/maps/place/${lat},${lng}`
    } else if (baiduMapsApiKey && 'baidu_maps'.includes(map)) {
      const locationName : string | undefined = this.locationRemote?.data?.tt_name
      const content = locationName ? `&content=${locationName}` : ''
      url = `https://map.baidu.com/?latlng=${lat},${lng}&title=DEKRA${content}&autoOpen=true`
    }

    return {
      url, iconName: 'location-dot', label, target: ELinkTarget.Blank, reversed: true,
    }
  }

  private setLocationInformation () : void {
    this.$store.commit('LocationInformation/setLocationImage', this.payload?.contactInformation?.structuredData?.image?.[0])

    const specialServicesList = this.locationRemote?.data?.tt_special_services_list
      ?.map((option) => option.value || '').filter((value) => value.length) || []

    if (this.locationRemote?.data?.tt_suitable_for_commercial_vehicles) {
      specialServicesList.push(globalLabelAsString('suitable_for_commercial_vehicles_label'))
    }
    this.$store.commit('LocationInformation/setSpecialServicesList', specialServicesList)
  }

  private setOnlineAppointmentLink () : void {
    const link : string | IReference | undefined = this.locationRemote?.data?.tt_link_online_appointment?.data?.lt_link
    // online appointment link is always 'string' (external link)
    if (link && typeof link === 'string') this.$store.commit('LocationOnlineAppointment/set', link)
  }

  private async showMessageIfLocationIsAlreadySaved () : Promise<void> {
    const savedLocationId = await this.$store.dispatch('Locations/isLocationIdSaved', this.locationRemote?.id)
    if (!savedLocationId) return
    this.$store.commit('MessageBar/set', {
      color: 'green',
      text: globalLabel('location_saved_location_page'),
      iconName: 'bookmark',
      iconType: 'solid',
    })
  }
}
