var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"empty:hidden"},[(_vm.clickableContent)?_c('section',{class:_vm.forceRatio ? `h-auto` : '!h-full'},[_c('div',{staticClass:"relative overflow-hidden",class:_vm.forceRatio ? `h-auto` : 'h-full'},[_c('figure',{staticClass:"overflow-hidden",class:[
          _vm.blurStyle,
          _vm.forceRatio ? `h-auto` : 'h-full'
        ]},[_c('section',{staticClass:"relative peer",class:[
            { 'cursor-pointer' : _vm.mediaType === 'video', },
            _vm.forceRatio ? `${_vm.cssAspectRatio[_vm.aspectRatio] || ''} h-auto` : 'h-full'
          ],attrs:{"title":_vm.title}},[_c(_vm.clickableContent.tag,_vm._b({tag:"component",attrs:{"aspect-ratio":_vm.aspectRatio}},'component',_vm.clickableContent.props,false)),_vm._v(" "),(_vm.clickableContent.tag === 'BasePicture')?_c('div',{staticClass:"absolute inset-0",class:_vm.lightbox ? 'cursor-pointer' : '',on:{"click":_vm.click}},[(_vm.lightbox)?_c('span',{staticClass:"absolute inset-0 opacity-0 hover:opacity-50 transition-opacity duration-300 bg-darkgreen peer"}):_vm._e(),_vm._v(" "),(_vm.mediaType === 'video')?_c('div',{staticClass:"absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 peer-hover:opacity-0 transition-opacity duration-300 bg-black w-[135px] max-w-[52%] rounded-full opacity-30 pointer-events-none aspect-square"}):_vm._e(),_vm._v(" "),_c('BaseIcon',{class:_vm.mediaType === 'video' ? 'opacity-100' : 'opacity-0 ml-[10px]',attrs:{"name":_vm.mediaType === 'video' ? 'play' : 'magnifying-glass-plus',"classes":"absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 pointer-events-none\n                h-14 peer-hover:opacity-100 transition-opacity duration-300 text-white max-h-[40%]","type":"light"}})],1):_vm._e()],1),_vm._v(" "),(_vm.caption)?_c('figcaption',{staticClass:"bg-gray-50 transition duration-300 p-4 text-sm",class:_vm.lightbox ? 'peer-hover:bg-darkgreen-50' : ''},[_vm._v("\n          "+_vm._s(_vm.caption)+"\n        ")]):_vm._e()]),_vm._v(" "),(_vm.showCookieLayer)?_c('CookieLayer',{directives:[{name:"show",rawName:"v-show",value:(_vm.mediaType === 'video'),expression:"mediaType === 'video'"}],staticClass:"absolute bottom-0 w-full",attrs:{"hint-text-target":'video',"no-video-button-accept":_vm.noVideoAcceptLabel,"external-button-accept":_vm.externalButtonAcceptLabel},on:{"accept-button-click":_vm.acceptCookies,"external-button-click":_vm.openVideoExternal}}):_vm._e()],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }