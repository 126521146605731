import {
  ComparisonQueryOperatorEnum, FSXAApi, QueryBuilderQuery, FetchByFilterParams,
} from 'fsxa-api'
import mapToResolutions from '../../general/services/ImageService'
import { ITeaserData, ITeasersData } from '../../general/interfaces/ITeaserData'
import ITagData from '../interfaces/ITagData'
import ITeaser, { ITeasers } from '../interfaces/ITeaser'
import { formatJoinFromToDate } from '../../general/services/DateTimeService'
import getLinkObject from './LinkService'
import { Logger } from '../../general/logger/Logger'
import fsxaProxyApiRemote from './FsxaProxyApiRemote'
import { TRemoteDatasetIndex } from '../types/TRemoteDataset'
import getOrFetchRemoteDatasets from './RemoteDatasetService'
import { getCurrentProjectName } from './ProjectService'

const fetch = async (fsxaApiLocal : FSXAApi, locale : string, type : 'local' | 'global', page ?: number, pageSize ?: number) : Promise<ITeasers> => {
  const filters : QueryBuilderQuery[] = [
    {
      field: 'entityType',
      operator: ComparisonQueryOperatorEnum.EQUALS,
      value: 'media_download',
    },
    {
      field: 'schema',
      operator: ComparisonQueryOperatorEnum.EQUALS,
      value: type === 'global' ? 'global_data' : 'local_data',
    },
    {
      field: 'formData.tt_show_in_this_language.value',
      operator: ComparisonQueryOperatorEnum.EQUALS,
      value: true,
    },
  ]

  if (type === 'global') {
    filters.push({
      field: 'formData.tt_subscribed_in_project_list.value.label',
      operator: ComparisonQueryOperatorEnum.IN,
      value: [await getCurrentProjectName(fsxaApiLocal, locale)],
    })
  }

  const additionalParams : Record<'keys' | string, any> = {
    sort: { 'formData.tt_teaser_headline': 1 },
    count: 1, // include "size" and "totalPages"
  }

  additionalParams.filter = {
    $and: [
      { 'formData.tt_media_asset.value': { $exists: true, $ne: [] } },
      { 'formData.tt_media_asset.value.0.formData.lt_link.value': { $exists: true, $type: 3 } }, // type: 3 - Object
    ],
  }

  try {
    const params : FetchByFilterParams = {
      filters,
      locale,
      page,
      pagesize: pageSize,
      additionalParams,
    }

    if (type === 'global') {
      const { items, size } = await fsxaProxyApiRemote.fetchByFilter(params)
      return { teasers: (items || []) as ITeaser[], total: size || 0 }
    }
    const { items, size } = await fsxaApiLocal.fetchByFilter(params)
    return { teasers: (items || []) as ITeaser[], total: size || 0 }
  } catch (error) {
    Logger.error(error)
    return { teasers: [], total: 0 }
  }
}

const mapTags = (tags ?: ITagData[]) : string => {
  if (!tags?.length) return ''
  return tags.map((tag : ITagData) => tag.data?.tt_name).filter((tag ?: string) => !!tag).join(' / ')
}

const mapTagsRemote = async (tags : TRemoteDatasetIndex) : Promise<string> => {
  const datasets = await getOrFetchRemoteDatasets(tags)
  return mapTags(datasets)
}

const normalizeTeaser = async (teaser : ITeaser, getUrlByPageId ?: Function) : Promise<ITeaserData> => ({
  image: {
    altText: teaser.data?.tt_teaser_image_alt_text || '',
    title: teaser.data?.tt_teaser_image_alt_text || '',
    resolutions: mapToResolutions(teaser.data?.tt_teaser_image?.resolutions),
  },
  headline: teaser.data?.tt_teaser_headline || '',
  date: formatJoinFromToDate({ from: teaser.data?.tt_publish_from }),
  tags: teaser.data?.tt_tags_remote?.value?.length
    ? await mapTagsRemote(teaser.data?.tt_tags_remote)
    : mapTags(teaser.data?.tt_tags),
  location: teaser.data?.tt_location,
  text: teaser.data?.tt_teaser_text,
  link: getUrlByPageId ? {
    ...getLinkObject(teaser.data?.tt_media_asset?.[0], getUrlByPageId),
    showInfo: true,
    displayInfoAs: 'row',
  } : {},
  mediaType: 'image',
})

const normalizeTeasers = async ({ teasers, total } : ITeasers, getUrlByPageId ?: Function) : Promise<ITeasersData> => {
  const promises = teasers.map((teaser : ITeaser) : Promise<ITeaserData> => normalizeTeaser(teaser, getUrlByPageId))
  return {
    teasers: await Promise.all(promises),
    total,
  }
}

const fetchNormalized = async (
  fsxaApi : FSXAApi,
  locale : string,
  type : 'local' | 'global',
  page ?: number,
  pagesize ?: number,
  getUrlByPageId ?: Function,
) : Promise<ITeasersData> => normalizeTeasers(await fetch(fsxaApi, locale, type, page, pagesize), getUrlByPageId)

export {
  fetch, fetchNormalized, normalizeTeasers,
}
