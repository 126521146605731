
import { Component } from 'nuxt-property-decorator'
import { FSXABaseSection } from 'fsxa-pattern-library'
import {
  File, Image, Reference, RichTextElement,
} from 'fsxa-api'
import IStageContent from '../../../shared/fsxa/interfaces/stage/IStageContent'
import { ILink } from '../../../shared/general/interfaces/ILink'
import IImageResolution from '../../../shared/general/interfaces/IImageResolution'
import mapToResolutions from '../../../shared/general/services/ImageService'
import getLinkObject from '../../../shared/fsxa/services/LinkService'

interface ISlideData {
  st_image ?: Image
  st_image_alt_text ?: string
  st_checkbox_1 ?: RichTextElement[]
  st_checkbox_2 ?: RichTextElement[]
  st_checkbox_3 ?: RichTextElement[]
  st_link ?: {
    data ?: {
      lt_link ?: Image | File | Reference | string
      lt_target ?: boolean
      lt_text ?: string
      lt_title_text ?: string
    }
  }
}

interface ISlide {
  id ?: string
  type ?: string
  sectionType ?: string
  data ?: ISlideData
}

interface IPayload {
  st_slides ?: ISlide[]
  st_design ?: boolean
}

@Component({
  name: 'StCareerStage',
  components: { Carousel: () => import('../../carousel/Carousel.vue') },
})
export default class StCareerStage extends FSXABaseSection<IPayload> {
  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }

  private get variant () : 'stage-medium' | 'stage-large' {
    return this.payload.st_design ? 'stage-medium' : 'stage-large'
  }

  private get slides () : IStageContent[] {
    return this.payload.st_slides?.map(({ data } : ISlide) => ({
      links: this.getLinks(data),
      text: '',
      image: {
        altText: data?.st_image_alt_text || '',
        title: data?.st_image_alt_text || '',
        resolutions: this.imageResolutions(data),
      },
      checkmarks: this.getCheckmarks(data),
    })) || []
  }

  private imageResolutions (slideData ?: ISlideData) : IImageResolution[] {
    if (!slideData?.st_image?.resolutions) return []
    return mapToResolutions(slideData?.st_image?.resolutions)
  }

  private getLinks (slideData ?: ISlideData) : ILink[] {
    return slideData?.st_link ? [getLinkObject(slideData.st_link, this.getUrlByPageId)] : []
  }

  private getCheckmarks (slideData ?: ISlideData) : RichTextElement[][] {
    return [
      (slideData?.st_checkbox_1 || []),
      (slideData?.st_checkbox_2 || []),
      (slideData?.st_checkbox_3 || []),
    ].filter((array) => array.length)
  }
}
