
import { Component, InjectReactive } from 'nuxt-property-decorator'
import { FSXABaseSection } from 'fsxa-pattern-library'
import { Image, Link, Section } from 'fsxa-api'
import IImage from '../../../shared/general/interfaces/IImage'
import { ITeaserData } from '../../../shared/general/interfaces/ITeaserData'
import mapToResolutions from '../../../shared/general/services/ImageService'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import getLinkObject from '../../../shared/fsxa/services/LinkService'
import { ILink } from '../../../shared/general/interfaces/ILink'

interface ITeaserPayload {
  data : {
    st_alt_text ?: string
    st_downloads ?: Section[]
    st_headline ?: string
    st_image ?: Image
    st_link ?: Link
    st_text ?: string
  }
}

interface IPayload {
  st_headline ?: string
  st_toggle_background ?: boolean
  st_teaser_type ?: {
    identifier ?: 'small' | 'large'
  }
  st_teasers ?: ITeaserPayload[]
}

@Component({
  name: 'StTeaserDownloadLink',
  components: {
    BaseTeaser: () => import('../../base/BaseTeaser.vue'),
    BaseGridLayout,
    BaseHeadline: () => import('../../base/BaseHeadline.vue'),
  },
})
export default class StTeaserDownloadLink extends FSXABaseSection<IPayload> {
  @InjectReactive({ from: 'gridSize' }) gridSize! : 'small' | 'wide'

  private width = {
    small: `basis-full sm:basis-[calc(50%_-_12px)] md:basis-[calc(100%_/_3_-_16px)] ${
      this.gridSize && this.gridSize === 'small' ? '' : 'lg:basis-[calc(25%_-_18px)]'
    }`,
    large: 'basis-full sm:basis-[calc(50%_-_12px)] md:basis-[calc(100%_/_3_-_16px)] lg:basis-[calc(100%_/_3_-_16px)]',
  }

  private get headline () : string {
    return this.payload.st_headline || ''
  }

  private get size () : string {
    return this.payload.st_teaser_type?.identifier || 'small'
  }

  private get teasers () : ITeaserData[] {
    return this.payload.st_teasers?.map((teaser : ITeaserPayload) : ITeaserData => {
      let link = {} as ILink

      if (teaser.data?.st_link) {
        link = getLinkObject(teaser.data.st_link, this.getUrlByPageId)
      } else {
        link = getLinkObject(teaser.data?.st_downloads?.[0], this.getUrlByPageId)
        link.showInfo = true
        link.displayInfoAs = 'row'

        if (!link.label) {
          link.globalLabelKey = 'download_label'
        }
      }

      link.inline = false

      return {
        image: this.mapImage(teaser),
        headline: teaser.data?.st_headline || '',
        text: teaser.data?.st_text,
        mediaType: 'image',
        link,
        forceRatio: true,
      }
    }) || []
  }

  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }

  private mapImage (teaser : ITeaserPayload): (IImage | undefined) {
    if (!teaser.data?.st_image) return undefined
    return {
      altText: teaser.data.st_alt_text || '',
      title: teaser.data.st_alt_text || '',
      resolutions: mapToResolutions(teaser.data.st_image.resolutions),
    }
  }

  private get background () : 'gray' | 'white' {
    return this.payload.st_toggle_background ? 'gray' : 'white'
  }

  private backgrounds = {
    white: 'bg-white',
    gray: 'bg-gray-100',
  }
}
