
import { File, Image, Option } from 'fsxa-api'
import { FSXABaseSection, FSXARichText } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'
import IMedia from '../../../shared/general/interfaces/IMedia'
import mapToResolutions from '../../../shared/general/services/ImageService'
import { buildPlayerElements } from '../../../shared/general/services/VideoUrlService'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'

interface IPayload {
  st_alt_text ?: string
  st_headline ?: string
  st_image ?: Image
  st_image_alignement ?: Option
  st_image_caption ?: string
  st_intro_text ?: string
  st_mp4 ?: File
  st_ogv ?: File
  st_webm ?: File
  st_text ?: FSXARichText
  st_title_text ?: string
  st_toggle_gap ?: boolean
  st_toggle_image_video ?: boolean
  st_video_alignement ?: Option
  st_video_caption ?: string
  st_video_description ?: string
  st_video_format ?: Option
  st_video_id ?: string
  st_vimeo_privacy_hash ?: string
  st_video_preview_image ?: Image
  st_zoom_image ?: Image
  st_zoom_image_active ?: boolean
  st_zoom_image_alt_text ?: string
}

@Component({
  name: 'StTextImage',
  components: {
    'rich-text': FSXARichText,
    TextImage: () => import('../../TextImage.vue'),
    BaseGridLayout,
  },
})
export default class StTextImage extends FSXABaseSection<IPayload> {
  private getAlignment () : string {
    const option = this.payload.st_toggle_image_video ? this.payload.st_image_alignement
      : this.payload.st_video_alignement

    if (!option?.key?.length) return 'centered'
    return option.key
  }

  private get toggleGap () : boolean {
    return !!this.payload.st_toggle_gap
  }

  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }

  private get headline () : string {
    return this.payload.st_headline || ''
  }

  private get introText () : string {
    return this.payload.st_intro_text || ''
  }

  private get content () : FSXARichText | undefined {
    return this.payload.st_text
  }

  private get mediaType () : 'video' | 'image' {
    return this.payload.st_toggle_image_video ? 'image' : 'video'
  }

  private get videoFormat () : 'internal' | 'external' {
    return this.payload.st_mp4 ?? this.payload.st_ogv ?? this.payload.st_webm ? 'internal' : 'external'
  }

  private get image () : Image | undefined {
    return this.payload.st_toggle_image_video ? this.payload.st_image : this.payload.st_video_preview_image
  }

  private get media () : IMedia | null {
    if (!this.image) return null

    const lightboxImage = {
      resolutions: mapToResolutions(this.payload.st_zoom_image?.resolutions),
      altText: this.payload.st_zoom_image_alt_text || '',
      title: this.payload.st_zoom_image_alt_text || '',
    }

    return {
      alignment: this.getAlignment(),
      media: {
        image: {
          resolutions: mapToResolutions(this.image?.resolutions),
          altText: this.payload.st_alt_text || '',
          title: this.payload.st_alt_text || '',
          caption: (this.mediaType === 'image' ? this.payload.st_image_caption : this.payload.st_video_caption) || '',
        },
        title: this.payload.st_title_text || '',
        lightboxImage: lightboxImage.resolutions.length ? lightboxImage : undefined,
        video: {
          id: this.payload.st_video_id || '',
          title: this.payload.st_video_caption,
          format: this.videoFormat,
          htmlPlayerElements: buildPlayerElements({
            mp4: this.payload.st_mp4,
            webm: this.payload.st_webm,
            ogv: this.payload.st_ogv,
          }),
          privacyHash: this.payload?.st_vimeo_privacy_hash,
        },
        lightbox: !!this.payload.st_zoom_image_active,
        mediaType: this.mediaType,
      },
    }
  }
}
