
import {
  Component, Inject, InjectReactive, Prop, ProvideReactive, Vue,
} from 'nuxt-property-decorator'
import getClassWithQuery from '../../shared/general/services/ContainerQueryService'

@Component({ name: 'BaseGridLayout' })
export default class BaseGridLayout extends Vue {
  @InjectReactive({ from: 'contained', default: false }) contained! : boolean

  @InjectReactive({ from: 'gridSize' }) parentGridSize! : 'small' | 'wide' | undefined

  @ProvideReactive('gridSize') gridSize : 'small' | 'wide' = 'wide'

  @Prop({ default: 'wide' }) size! : 'small' | 'wide'

  @Inject({ from: 'ignoreGridSizeUseWidthFull', default: false }) ignoreGridSizeUseWidthFull! : boolean

  private get styles () : string {
    if (this.ignoreGridSizeUseWidthFull) return ''

    const sizes = {
      wide: 'max-w-[82.5rem] sm:px-6',
      small: `${this.getClassWithQuery('md:max-w-[52.5rem] sm:px-6 lg:px-0')}`,
    }

    return sizes[this.size]
  }

  private getClassWithQuery (classes : string) : string {
    return getClassWithQuery(this.contained, classes)
  }

  created () {
    this.gridSize = this.parentGridSize === 'small' || this.size === 'small' ? 'small' : 'wide'
  }
}
