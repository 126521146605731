
import { Component } from 'nuxt-property-decorator'
import BaseTeaserRc from '../base/BaseTeaserRc'
import IImage from '../../../shared/general/interfaces/IImage'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import mapToResolutions from '../../../shared/general/services/ImageService'
import IOption from '../../../shared/general/interfaces/IOption'
import { ITeaserData } from '../../../shared/general/interfaces/ITeaserData'
import { formatJoinFromToDate } from '../../../shared/general/services/DateTimeService'
import getOrFetchRemoteDatasets, { getRemoteDatasetsFromStore } from '../../../shared/fsxa/services/RemoteDatasetService'
import { TRemoteDatasetIndex } from '../../../shared/fsxa/types/TRemoteDataset'
import IPressReleaseData from '../../../shared/fsxa/interfaces/IPressReleaseData'

interface IPayload {
  st_box_position ?: boolean
  st_column ?: IOption
  st_image_text ?: Boolean
  st_press_release_global_remote ?: TRemoteDatasetIndex
  st_press_release_local ?: IPressReleaseData[]
}

@Component({
  name: 'StTeaserRcPress',
  components: {
    BaseGridLayout,
    BaseTeaser: () => import('../../base/BaseTeaser.vue'),
  },
})
export default class StTeaserRcPress extends BaseTeaserRc<IPayload> {
  async serverPrefetch () {
    await this.fetchRemoteDatasets()
  }

  async mounted () {
    await this.fetchRemoteDatasets()
    super.mounted()
  }

  async fetchRemoteDatasets () : Promise<void> {
    await getOrFetchRemoteDatasets(this.pressReleaseGlobalDatasetIndex)
  }

  private get widthClass () : string {
    return this.numberOfColumns === 2
      ? 'w-full sm:w-[calc(100%/2-1.125rem)] md:w-[calc(100%/3-1.125rem)] '
        + 'lg:w-[calc(((100%/4)*2)-1.125rem)] lg:aspect-video'
      : 'w-full sm:w-[calc(100%/2-1.125rem)] md:w-[calc(100%/3-1.125rem)] lg:w-[calc(100%/4-1.125rem)]'
  }

  private get pressFromPayload () : IPressReleaseData | undefined {
    return [...(this.pressReleasesGlobalRemote || []), ...(this.payload.st_press_release_local || [])][0]
  }

  private get teaserData () : ITeaserData {
    const image : IImage = {
      altText: this.pressFromPayload?.data?.tt_teaser_image_alt_text || '',
      title: this.pressFromPayload?.data?.tt_teaser_image_alt_text || '',
      resolutions: mapToResolutions(this.pressFromPayload?.data?.tt_teaser_image?.resolutions),
    }

    return {
      headline: this.pressFromPayload?.data?.tt_headline || '',
      text: this.pressFromPayload?.data?.tt_overline || '',
      image,
      link: {
        url: this.pressFromPayload?.route || this.pressFromPayload?.routes?.[0]?.route,
        globalLabelKey: 'specific_press_link_label',
        iconName: 'chevron-right',
      },
      mediaType: this.payload.st_image_text ? 'image' : 'text',
      date: formatJoinFromToDate({ from: this.pressFromPayload?.data?.tt_publish_from }),
    }
  }

  private get pressReleaseGlobalDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.payload.st_press_release_global_remote
  }

  private get pressReleasesGlobalRemote () : IPressReleaseData[] {
    return getRemoteDatasetsFromStore(this.pressReleaseGlobalDatasetIndex)
  }
}
