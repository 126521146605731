
import { Component } from 'nuxt-property-decorator'
import { FSXABaseSection } from 'fsxa-pattern-library'
import { ITeaserData } from '../../../shared/general/interfaces/ITeaserData'
import ITagData from '../../../shared/fsxa/interfaces/ITagData'
import ITeaser from '../../../shared/fsxa/interfaces/ITeaser'
import { normalizeTeaserImage, searchTeasers } from '../../../shared/general/services/SmartSearchTeaserService'
import { ISmartSearchMediaHost } from '../../../shared/smartsearch/ExtractTeaserImage'
import ISmartSearchHost from '../../../shared/general/interfaces/ISmartSearchHost'
import TeaserArticlesHome from '../../teaser/articles/TeaserArticlesHome.vue'
import { mapToTeaserData, sortDescending } from '../../../shared/fsxa/services/TeaserArticleService'
import { mapTags } from '../../../shared/fsxa/services/TagService'
import { TRemoteDatasetIndex } from '../../../shared/fsxa/types/TRemoteDataset'
import getOrFetchRemoteDatasets, { getRemoteDatasetsFromStore } from '../../../shared/fsxa/services/RemoteDatasetService'

interface IPayload {
  st_headline ?: string
  st_tags_remote ?: TRemoteDatasetIndex
  st_global_articles_remote ?: TRemoteDatasetIndex
  st_local_articles ?: ITeaser[]
}

@Component({
  name: 'StTeaserArticlesHome',
  components: {
    TeaserArticlesHome,
  },
})
export default class StTeaserArticlesHome extends FSXABaseSection<IPayload> {
  private teasers : ITeaserData[] = []

  private pageNumber : number = 1

  private pageSize : number = 4

  private showLoadingAnimation : boolean = true

  async serverPrefetch () {
    await this.fetchRemoteDatasets()
  }

  async mounted () {
    await this.fetchRemoteDatasets()
    await this.loadTeasers()
  }

  private async fetchRemoteDatasets () : Promise<void> {
    await Promise.all([
      ...this.localArticles.map((teaser) => getOrFetchRemoteDatasets(teaser?.data?.tt_tags_remote)),
      getOrFetchRemoteDatasets(this.globalArticlesRemoteDatasetIndex),
      getOrFetchRemoteDatasets(this.tagsRemoteDatasetIndex),
    ])
  }

  private async loadTeasers () : Promise<void> {
    if (this.manualTeasers.length) {
      this.teasers = this.manualTeasers.slice(0, this.pageSize)
    } else {
      this.teasers.push(...await this.getTeasers())
    }
    this.showLoadingAnimation = false
  }

  private get tagsRemoteDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.payload.st_tags_remote
  }

  private get headline () : string | undefined {
    return this.payload.st_headline
  }

  private get tagsRemote () : ITagData[] {
    return getRemoteDatasetsFromStore(this.tagsRemoteDatasetIndex)
  }

  private get globalArticlesRemoteDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.payload.st_global_articles_remote
  }

  private get globalArticlesRemote () : ITeaser[] {
    return getRemoteDatasetsFromStore(this.globalArticlesRemoteDatasetIndex)
  }

  private get localArticles () : ITeaser[] {
    return this.payload.st_local_articles || []
  }

  private get mappedTags () : string[] {
    return mapTags(this.tagsRemote)
  }

  private get smartSearchHost () : ISmartSearchHost {
    return {
      host: this.$config.SMART_SEARCH_HOST,
      preparedSearch: this.$config.PREPARED_SEARCH_TEASER_OVERVIEW,
      projectId: this.$config.FSXA_PROJECT_ID,
      remoteProjectId: this.$config.FSXA_REMOTES?.media?.id || '',
    }
  }

  private get smartSearchMediaHost () : ISmartSearchMediaHost {
    return {
      mediaHost: this.$config.SMART_SEARCH_MEDIA_HOST,
      projectId: this.$config.FSXA_PROJECT_ID,
      remotes: this.$config.FSXA_REMOTES,
    }
  }

  private get manualTeasers () : ITeaserData[] {
    const articles = [...this.localArticles, ...this.globalArticlesRemote]
    return articles.sort(sortDescending).map((article) => mapToTeaserData(article))
  }

  private get overviewPageUrl () : string {
    return this.getUrlByPageId(this.articleOverviewPageId) || '#'
  }

  private get articleOverviewPageId () : string {
    return this.globalSettings?.data?.ps_country_project_settings?.pt_article_overview_page?.referenceId || ''
  }

  private async getTeasers () : Promise<ITeaserData[]> {
    const result = await searchTeasers(this.smartSearchHost, {
      locale: this.locale,
      teaserType: 'article',
      teaserTags: this.mappedTags,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      sort: ['sortDateFrom', 'desc'],
    })
    return result.searchResults
      .map((searchResult) => normalizeTeaserImage(searchResult, this.smartSearchMediaHost, (searchRes) => ({
        from: searchRes.publishFrom,
        to: searchRes.publishTo,
      })))
  }
}
