import { CaaSApiMediaPictureResolutions } from 'fsxa-api'
import IImageResolution from '../interfaces/IImageResolution'

const mapToResolutions = (resolutions : null | CaaSApiMediaPictureResolutions = {}) : IImageResolution[] => {
  if (!resolutions) return []
  const entries = Object.entries(resolutions)
  return entries.map<IImageResolution>(([name, values]) => ({ name, ...values }))
}

export default mapToResolutions
