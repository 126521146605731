
import { Component, Provide } from 'nuxt-property-decorator'
import { FSXABaseSection } from 'fsxa-pattern-library'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import ITeaserOverviewPayload from '../../../shared/fsxa/interfaces/ITeaserOverviewPayload'
import { ISmartSearchMediaHost } from '../../../shared/smartsearch/ExtractTeaserImage'
import getOrFetchRemoteDatasets, { getRemoteDatasetsFromStore } from '../../../shared/fsxa/services/RemoteDatasetService'
import { TRemoteDatasetIndex } from '../../../shared/fsxa/types/TRemoteDataset'
import ITagData from '../../../shared/fsxa/interfaces/ITagData'

interface IPayload extends ITeaserOverviewPayload {
  st_activate_filtering_by_date ?: boolean
  st_tag_list_remote ?: TRemoteDatasetIndex
}

@Component({
  name: 'StTeaserOverviewPress',
  components: {
    BaseGridLayout,
    TeaserOverview: () => import('../../teaser/overview/TeaserOverview.vue'),
  },
})
export default class StTeaserOverviewPress extends FSXABaseSection<IPayload> {
  async serverPrefetch () {
    await this.fetchRemoteDatasets()
  }

  async mounted () {
    await this.fetchRemoteDatasets()
  }

  async fetchRemoteDatasets () : Promise<void> {
    await getOrFetchRemoteDatasets(this.tagListDatasetIndex)
  }

  @Provide() smartSearchMediaHost : ISmartSearchMediaHost = {
    mediaHost: this.$config.SMART_SEARCH_MEDIA_HOST,
    projectId: this.$config.FSXA_PROJECT_ID,
    remotes: this.$config.FSXA_REMOTES,
  }

  private numberOfTeasers : number | undefined = +(this.payload.st_number_of_teasers?.identifier || 0) || undefined

  private showTagFilter : boolean = !!this.payload.st_show_tag_filter

  private activateFilteringByDate : boolean = !!this.payload.st_activate_filtering_by_date

  private customParams : [string, string][] = [
    ['facet.filter.FS_template_uid', 'local_data.tt_press_release'],
    ['facet.filter.FS_template_uid', 'global_data.tt_press_release'],
  ]

  private filterQueries : [string, string] = ['FS_publish_from_date_range', '[* TO NOW]']

  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }

  private get tagListDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.payload.st_tag_list_remote
  }

  private get tags () : string[] {
    return this.tagListRemote.map((tag) => tag.data?.tt_name || '').filter((tagName) => tagName.length)
  }

  private get tagListRemote () : ITagData[] {
    return getRemoteDatasetsFromStore(this.tagListDatasetIndex)
  }
}
