
import { File, Image, Reference } from 'fsxa-api'
import { FSXABaseSection } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'
import IImageResolution from '../../../shared/general/interfaces/IImageResolution'
import IStageContent from '../../../shared/fsxa/interfaces/stage/IStageContent'
import mapToResolutions from '../../../shared/general/services/ImageService'
import { ILink } from '../../../shared/general/interfaces/ILink'
import getLinkObject from '../../../shared/fsxa/services/LinkService'
import { buildPlayerElements } from '../../../shared/general/services/VideoUrlService'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'

interface ISlideData {
  st_headline ?: string
  st_alt_text ?: string
  st_image ?: Image
  st_links ?: {
    data ?: {
      lt_link ?: Image | File | Reference | string
      lt_target ?: boolean
      lt_text ?: string
      lt_title_text ?: string
    }
  }[]
  st_mp4 ?: File
  st_ogv ?: File
  st_webm ?: File
  st_slide_text ?: string
  st_toggle_video ?: boolean
  st_video_format ?: {
    key ?: string
    value ?: string
  }
  st_video_id ?: string
  st_vimeo_privacy_hash ?: string
}

interface ISlide {
  id ?: string
  type ?: string
  sectionType ?: string
  data ?: ISlideData
}

interface IPayload {
  st_slides ?: ISlide[]
  st_design ?: boolean
}

@Component({
  name: 'StTabSlider',
  components: {
    BaseGridLayout,
    Carousel: () => import('../../carousel/Carousel.vue'),
  },
})
export default class StTabSlider extends FSXABaseSection<IPayload> {
  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }

  private get slides () : IStageContent[] {
    return this.payload.st_slides?.map(({ data } : ISlide) => ({
      links: this.getLinks(data),
      headline: data?.st_headline,
      text: data?.st_slide_text || '',
      image: {
        altText: data?.st_alt_text || '',
        title: data?.st_alt_text || '',
        resolutions: this.imageResolutions(data),
      },
      video: {
        id: data?.st_video_id || '',
        format: data?.st_video_format?.value?.toLowerCase() === 'html5' ? 'internal' : 'external',
        htmlPlayerElements: buildPlayerElements({
          mp4: data?.st_mp4,
          webm: data?.st_webm,
          ogv: data?.st_ogv,
        }),
        privacyHash: data?.st_vimeo_privacy_hash,
      },
    })) || []
  }

  private imageResolutions (images ?: ISlideData) : IImageResolution[] {
    if (!images?.st_image) return []
    return mapToResolutions(images?.st_image?.resolutions)
  }

  private getLinks (buttons ?: ISlideData) : ILink[] {
    return buttons?.st_links?.map<ILink>((link) => getLinkObject(link, this.getUrlByPageId)) || []
  }
}
