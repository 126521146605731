
import { FSXABaseSection } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'
import { Section } from 'fsxa-api'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import IImage from '../../../shared/general/interfaces/IImage'
import mapToResolutions from '../../../shared/general/services/ImageService'
import getLinkObject from '../../../shared/fsxa/services/LinkService'

@Component({
  name: 'StReferences',
  components: {
    BaseGridLayout,
    References: () => import('../../References.vue'),
  },
})
export default class StReferences extends FSXABaseSection {
  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }

  private get headline () : string {
    return this.payload?.st_headline || ''
  }

  private get references () : IImage {
    return this.payload?.st_references_list?.map((reference : Section) => ({
      link: getLinkObject(reference.data?.st_link, this.getUrlByPageId),
      image: {
        altText: reference.data?.st_alt_text,
        resolutions: mapToResolutions(reference.data?.st_image?.resolutions),
      },
    }))
  }
}
