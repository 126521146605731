var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_vm.componentTag,{directives:[{name:"show",rawName:"v-show",value:(_vm.url || _vm.onlySlotContent),expression:"url || onlySlotContent"}],tag:"component",staticClass:"max-w-full !no-underline group items-baseline",class:[
    _vm.overrideStyles ? '' : `${_vm.styles} cursor-pointer gap-1.5`,
    _vm.reversed ? 'flex-row-reverse justify-end' : 'flex-row justify-center',
    { 'w-max' : _vm.wMax && _vm.reversed },
    _vm.inline ? 'inline-flex' : 'flex',
    {
      'internal-link' : _vm.internal,
      '!items-center' : _vm.type === 'button',
      'transition-all duration-300' : _vm.showDefaultTransition,
    }
  ],attrs:{"href":_vm.componentTag === 'a' && _vm.href,"target":_vm.componentTag === 'a' && _vm.target,"title":_vm.title || false},on:{"click":_vm.click}},[(_vm.hasSlotContent)?_vm._t("default"):_vm._e(),_vm._v(" "),(!_vm.onlySlotContent && (_vm.showLabel || _vm.showInfo) && (_vm.globalLabelValue || _vm.breakableLabel))?_c('span',{staticClass:"h-full justify-center",class:[{
      'pt-px' : !_vm.inline,
      'w-full flex justify-between gap-3 whitespace-nowrap' : _vm.displayInfoAs === 'row-separate',
      'flex flex-col' : _vm.displayInfoAs === 'col',
      'inline items-center' : _vm.displayInfoAs === 'row',
    }]},[(_vm.showLabel && (_vm.globalLabelValue || _vm.breakableLabel))?_c('span',{staticClass:"!whitespace-normal text",class:[
        _vm.underline,
        { '!no-underline' : _vm.type === 'button' },
      ],staticStyle:{"word-break":"break-word"}},[_vm._v("\n      "+_vm._s(_vm.globalLabelValue || _vm.breakableLabel)+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.info && _vm.showInfo)?_c('span',{staticClass:"self-start",class:{
        'group-hover:text-white transition-colors duration-300' : _vm.infoGroupHover,
        'text-gray-500' : ['col', 'row-separate'].includes(_vm.displayInfoAs),
        'group-hover:underline group-focus:underline group-active:underline' : _vm.displayInfoAs === 'row',
      }},[_vm._v("\n      "+_vm._s(_vm.info)+"\n    ")]):_vm._e()]):_vm._e(),_vm._v(" "),(!_vm.onlySlotContent && (_vm.imageSrcPath || (_vm.iconName && !_vm.withoutIcon)))?_c('span',{staticClass:"visual-wrapper w-max h-full self-center flex items-center",style:(_vm.multiLine ? `margin-top: -${_vm.lineHeight - 1}px` : '')},[(_vm.imageSrcPath)?_c('img',{staticClass:"w-auto h-full",attrs:{"src":_vm.imageSrcPath,"alt":_vm.imageAlt || _vm.type,"title":_vm.imageAlt || false,"loading":"lazy"}}):_vm._e(),_vm._v(" "),(_vm.iconName && !_vm.withoutIcon)?_c('BaseIcon',{staticClass:"inline",attrs:{"name":_vm.iconName,"type":_vm.iconType,"classes":_vm.iconSize}}):_vm._e()],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }