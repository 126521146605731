import { File } from 'fsxa-api'
import { IHtmlPlayerElements } from '../interfaces/IHtmlPlayerElements'

export const isYouTubeVideo = (videoId : string) : boolean => /^[a-zA-Z\d_-]{11}$/.test(videoId)

export const isVimeoVideo = (videoId : string) : boolean => /^\d{8,}$/.test(videoId)

const urlLinksPicker = {
  youtube: {
    lightbox: (videoId : string) => `https://www.youtube.com/embed/${videoId}?autoplay=1&muted=1`,
    redirect: (videoId : string) => `https://www.youtube.com/watch?v=${videoId}`,
  },
  vimeo: {
    lightbox: (videoId : string, privacyHash ?: string) => {
      const baseUrl : string = `https://player.vimeo.com/video/${videoId}`
      if (!privacyHash) return `${baseUrl}?autoplay=1&muted=1`
      return `${baseUrl}?h=${privacyHash}&autoplay=1&muted=1`
    },
    redirect: (videoId : string) => `https://vimeo.com/${videoId}`,
  },
}

export const getVideoUrl = (videoId : string, redirect : boolean, privacyHash ?: string) : string => {
  if (isYouTubeVideo(videoId)) {
    return redirect ? urlLinksPicker.youtube.redirect(videoId) : urlLinksPicker.youtube.lightbox(videoId)
  }
  if (isVimeoVideo(videoId)) {
    return redirect ? urlLinksPicker.vimeo.redirect(videoId) : urlLinksPicker.vimeo.lightbox(videoId, privacyHash)
  }
  return ''
}

interface IBuildPlayerElementsProps {
  ogv ?: File
  webm ?: File
  mp4 ?: File
}

export const buildPlayerElements = (props : IBuildPlayerElementsProps) : IHtmlPlayerElements => {
  const addPlayerElement = (player : IHtmlPlayerElements, videoFormat : 'mp4' | 'webm' | 'ogv') => {
    if (!props[videoFormat]) return

    player[videoFormat] = {
      url: props[videoFormat]?.url || '#',
      mimeType: props[videoFormat]?.fileMetaData?.mimeType || `video/${videoFormat}`,
    }
  }

  const playerElements = {} as IHtmlPlayerElements
  addPlayerElement(playerElements, 'mp4')
  addPlayerElement(playerElements, 'webm')
  addPlayerElement(playerElements, 'ogv')
  return playerElements
}
