import { ComparisonQueryOperatorEnum, QueryBuilderQuery } from 'fsxa-api'
import ILocationData, { ILocationTypeFilterData } from '../interfaces/ILocationData'
import fsxaProxyApiRemote from './FsxaProxyApiRemote'

const searchLocations = async (
  locale : string,
  border : { latFrom : number, latTo : number, lngFrom : number, lngTo : number },
  locationTypes : ILocationTypeFilterData[],
  commercialVehicles ?: boolean,
)
  : Promise<ILocationData[]> => {
  const filters : QueryBuilderQuery[] = [
    {
      field: 'entityType',
      operator: ComparisonQueryOperatorEnum.EQUALS,
      value: 'location',
    },
    {
      field: 'schema',
      operator: ComparisonQueryOperatorEnum.IN,
      value: ['global_data'],
    },
  ]

  if (locationTypes?.length) {
    filters.push({
      field: 'formData.tt_location_types.value.value.target.identifier',
      operator: ComparisonQueryOperatorEnum.IN,
      value: locationTypes.map((type) => type.id).filter((el) : el is string => !!el),
    })
  }

  if (commercialVehicles) {
    filters.push({
      field: 'formData.tt_suitable_for_commercial_vehicles.value',
      operator: ComparisonQueryOperatorEnum.EQUALS,
      value: true,
    })
  }

  const deletedFilter = { 'formData.tt_deleted.value': { $eq: false } }

  const lat = [
    { 'formData.tt_latitude.value': { $gte: border.latFrom } },
    { 'formData.tt_latitude.value': { $lte: border.latTo } },
  ]
  const lng = [
    { 'formData.tt_longitude.value': { $gte: border.lngFrom } },
    { 'formData.tt_longitude.value': { $lte: border.lngTo } },
  ]

  const borderFilter = {
    $and: [...lat, ...lng],
  }

  const additionalFilter = {
    $and: [
      deletedFilter,
      borderFilter,
    ],
  }

  const { items } = await fsxaProxyApiRemote.fetchByFilter({
    filters,
    locale,
    page: 1,
    pagesize: 100,
    additionalParams: {
      filter: additionalFilter,
    },
  })

  return (items || []) as ILocationData[]
}

export default searchLocations
