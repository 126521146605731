
import {
  Component, Emit, Prop, Vue,
} from 'nuxt-property-decorator'
import BaseInput from './form/BaseInput.vue'
import BaseButton from './BaseButton.vue'
import { globalLabelAsString } from '../../shared/general/services/StoreService'

@Component({
  name: 'BaseSearchbar',
  components: {
    BaseInput,
    BaseButton,
  },
})
export default class BaseSearchbar extends Vue {
  @Prop({ required: true }) isMobile! : boolean

  $refs! : {
    baseInput : BaseInput
  }

  private value : string = ''

  private get searchButtonLabel () : string {
    return globalLabelAsString('search_button_label')
  }

  private get searchInputPlaceholder () : string {
    return globalLabelAsString('searchfield_label')
  }

  private styles = {
    container: {
      true: 'flex-col px-4 gap-4',
      false: 'flex-row',
    },
    button: {
      true: 'w-full h-14',
      false: 'w-48',
    },
  }

  private valueChanged (value : string) : void {
    this.value = value
  }

  @Emit('search')
  private search () : string {
    return this.value
  }

  private searchOrFocusInput () : void {
    if (this.value) {
      this.search()
    } else {
      this.focusInput()
    }
  }

  public focusInput () : void {
    this.$refs.baseInput?.focus()
  }
}
