
import { FSXABaseSection } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'
import ILinkData from '../../../shared/fsxa/interfaces/ILinkData'
import { trackCta } from '../../../shared/general/tracking/TrackingService'
import getLinkObject from '../../../shared/fsxa/services/LinkService'
import { ILink } from '../../../shared/general/interfaces/ILink'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import { TRemoteDatasetIndex } from '../../../shared/fsxa/types/TRemoteDataset'
import getOrFetchRemoteDatasets, { getRemoteDatasetsFromStore } from '../../../shared/fsxa/services/RemoteDatasetService'

interface ITeaserData {
  icon ?: string
  iconRemote ?: TRemoteDatasetIndex
  text ?: string
  title ?: string
  link ?: ILink
}

interface ICtaIconLink {
  st_fontawesome_icon_remote ?: TRemoteDatasetIndex
  st_link ?: ILinkData
}

interface ICtaData {
  sectionType ?: 'st_cta'
  data ?: ICtaIconLink
}

interface ILocationOnlineAppointmentLink {
  st_link_text ?: string
  st_title_text ?: string
  st_show_default_link ?: boolean
  st_link ?: ILinkData
}

interface ILocationOnlineAppointmentCtaData {
  sectionType ?: 'st_location_online_appointment_cta'
  data ?: ILocationOnlineAppointmentLink
}

interface IPayload {
  st_headline ?: string
  st_teaser ?: (ICtaData | ILocationOnlineAppointmentCtaData)[]
}

@Component({
  name: 'StContainerCta',
  components: {
    BaseHeadline: () => import('../../base/BaseHeadline.vue'),
    BaseIcon: () => import('../../base/BaseIcon.vue'),
    BaseLink: () => import('../../base/BaseLink.vue'),
    BaseGridLayout,
  },
})
export default class StContainerCta extends FSXABaseSection<IPayload> {
  async serverPrefetch () {
    await this.fetchRemoteDatasets()
  }

  async mounted () {
    await this.fetchRemoteDatasets()
  }

  private async fetchRemoteDatasets () : Promise<void> {
    await Promise.all(this.ctaArray.map((teaser) => getOrFetchRemoteDatasets(teaser.iconRemote)))
  }

  private get teasers () : ITeaserData[] {
    const iconsRemote : string[] = this.ctaArray
      .map((teaser) => getRemoteDatasetsFromStore(teaser.iconRemote))
      .map((dataset) => dataset?.[0]?.data?.tt_fontawesome_v6_id || '')

    // if we already have a hard coded icon - we don't want to override it
    return this.ctaArray.map((teaser, index) => ({ ...teaser, icon: teaser.icon || iconsRemote[index] }))
  }

  private get headline () : string {
    return this.payload.st_headline || ''
  }

  private get ctaArray () : ITeaserData[] {
    const isLocationOnlineAppointment = (teaser : ICtaData | ILocationOnlineAppointmentCtaData)
      : teaser is ILocationOnlineAppointmentCtaData => (teaser.sectionType === 'st_location_online_appointment_cta')

    return this.payload.st_teaser?.map((teaser) => (isLocationOnlineAppointment(teaser)
      ? this.locationOnlineAppointmentTeaser(teaser)
      : this.ctaTeaser(teaser))) || []
  }

  private ctaTeaser (teaser : ICtaData) : ITeaserData {
    return {
      link: getLinkObject(teaser.data?.st_link, this.getUrlByPageId),
      iconRemote: teaser.data?.st_fontawesome_icon_remote,
      text: teaser.data?.st_link?.data?.lt_text,
      title: teaser.data?.st_link?.data?.lt_title_text,
    }
  }

  private locationOnlineAppointmentTeaser (teaser : ILocationOnlineAppointmentCtaData) : ITeaserData {
    const { link } : { link : string } = this.$store.state.LocationOnlineAppointment
    if (link) {
      return {
        link: { url: link },
        icon: 'calendar-days',
        text: teaser.data?.st_link_text,
        title: teaser.data?.st_title_text,
      }
    }
    if (teaser.data?.st_show_default_link && teaser.data?.st_link) {
      return {
        link: getLinkObject(teaser.data.st_link, this.getUrlByPageId),
        icon: 'calendar-days',
        text: teaser.data.st_link.data?.lt_text,
        title: teaser.data.st_link.data?.lt_title_text,
      }
    }
    return {}
  }

  private click (link ?: ILink, iconName ?: string) : void {
    trackCta(this.$el as HTMLElement, {
      link_text: link?.label || '',
      link_url: link?.url || '',
      icon_name: iconName || '',
      cta_type: 'cta_container',
    })
  }

  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }
}
