import {
  getSmartSearchInstance,
  TSmartSearchMapping,
  TSmartSearchParams,
  TSmartSearchResults,
  search,
} from './SmartSearchService'
import ISmartSearchHost from '../interfaces/ISmartSearchHost'
import { TSort } from '../types/TTeaserSortBy'
import { ITeaserData } from '../interfaces/ITeaserData'
import { formatJoinFromToDate } from './DateTimeService'
import getTeaserImage, { ISmartSearchMediaHost } from '../../smartsearch/ExtractTeaserImage'

export type TTeaserType = 'event' | 'press_release' | 'article'

export type TTeaserSearchResult = {
  link : string
  headline : string
  text : string
  image : string
  imageAlt : string
  tags : string
  publishFrom : string
  publishTo : string
  location : string
  templateUid : string
}

const fieldMapping = () : TSmartSearchMapping<TTeaserSearchResult> => ({
  link: { key: '' }, // key value is not important
  headline: { key: 'FS_headline' },
  text: { key: 'FS_text' },
  image: { key: 'FS_image' },
  imageAlt: { key: 'FS_image_alt_text' },
  tags: { key: 'FS_tags' },
  publishFrom: { key: 'FS_publish_from_date_range' },
  publishTo: { key: 'FS_publish_to_date_range' },
  location: { key: 'FS_event_location' },
  templateUid: { key: 'FS_template_uid_facet_string' },
})

const filterByDate = (teaserType : TTeaserType) : [string, string][] => {
  switch (teaserType) {
    case 'article':
    case 'press_release':
      return [['fq', 'FS_publish_from_date_range:[* TO NOW]']]
    case 'event':
      return [['fq', 'FS_publish_to_date_range:[NOW TO *]']]
    default:
      return [['', '']]
  }
}

export const searchTeasers = async (
  smartSearchHost : ISmartSearchHost,
  searchParams : {
    locale : string
    teaserType : TTeaserType
    teaserTags ?: string[]
    pageNumber ?: number
    pageSize ?: number
    sort ?: TSort
    searchTerm ?: string
  },
  customParams ?: [string, string][],
)
  : Promise<TSmartSearchResults<TTeaserSearchResult>> => {
  const {
    host, preparedSearch, projectId, remoteProjectId,
  } = smartSearchHost
  if (!host || !preparedSearch || !projectId || !remoteProjectId) return { searchResults: [], facets: [], totalNumberOfResults: 0 }
  const {
    locale, teaserType, teaserTags, pageNumber, pageSize, sort, searchTerm,
  } = searchParams

  const smartSearchService = getSmartSearchInstance({ host, preparedSearch })
  const smartSearch = await smartSearchService()

  const teaserParams : [string, string][] = []
  switch (teaserType) {
    case 'article':
      teaserParams.push(['facet.filter.FS_template_uid', 'local_data.tt_article'])
      teaserParams.push(['facet.filter.FS_template_uid', 'global_data.tt_article'])
      break
    case 'event':
      teaserParams.push(['facet.filter.FS_template_uid', 'local_data.tt_event'])
      teaserParams.push(['facet.filter.FS_template_uid', 'global_data.tt_event'])
      break
    case 'press_release':
      teaserParams.push(['facet.filter.FS_template_uid', 'local_data.tt_press_release'])
      teaserParams.push(['facet.filter.FS_template_uid', 'global_data.tt_press_release'])
      break
    default:
      break
  }

  const tags : string[] = teaserTags || []
  const tagsFilter : [string, string][] = tags.map((tag : string) => (['facet.filter.FS_tags', tag || ''] as [string, string]))

  const params : TSmartSearchParams = {
    searchTerm: searchTerm || '*',
    pageNumber: pageNumber || 1,
    pageSize: pageSize || 100,
    locale,
    customParams: [
      ...teaserParams,
      ['facet.filter.FS_project_uuid', projectId],
      ['facet.filter.FS_project_uuid', remoteProjectId],
      ['facet.filter.FS_show_in_this_language', 'true'],
      ['facet.filter.FS_subscribed_projects', projectId],
      ...tagsFilter,
      sort || ['', ''],
      ...filterByDate(teaserType),
      ...(customParams || []),
    ],
  }
  return search(smartSearch, fieldMapping(), params)
}

export const normalizeTeaserText = (
  searchResult : TTeaserSearchResult,
  dateMapping ?: (searchResult : TTeaserSearchResult) => { from ?: string, to ?: string },
) : ITeaserData => ({
  link: {
    url: searchResult.link,
  },
  headline: searchResult.headline,
  text: searchResult.text,
  date: dateMapping ? formatJoinFromToDate(dateMapping(searchResult)) : '',
  location: searchResult.location,
  mediaType: 'text',
})

export const normalizeTeaserImage = (
  searchResult : TTeaserSearchResult,
  smartSearchMediaHost : ISmartSearchMediaHost,
  dateMapping ?: (searchResult : TTeaserSearchResult) => { from ?: string, to ?: string },
) : ITeaserData => ({
  link: {
    url: searchResult.link,
  },
  headline: searchResult.headline,
  image: getTeaserImage(
    smartSearchMediaHost,
    searchResult.image,
    searchResult.templateUid,
    searchResult.imageAlt,
  ),
  text: searchResult.text,
  date: dateMapping ? formatJoinFromToDate(dateMapping(searchResult)) : '',
  location: searchResult.location,
  mediaType: searchResult.image ? 'image' : 'text',
  aspectRatio: '16:9',
  forceRatio: true,
  teaserVariant: 'vertical',
})
