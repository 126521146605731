
import { FSXABaseSection } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'
import { TIcon } from '../../../shared/general/types/TIcon'
import { TRemoteDatasetIndex } from '../../../shared/fsxa/types/TRemoteDataset'
import getOrFetchRemoteDatasets, { getRemoteDatasetsFromStore } from '../../../shared/fsxa/services/RemoteDatasetService'

interface ILinkPayload {
  referenceId : string
  referenceType : string
  type : string
}

interface IPayload {
  st_fontawesome_icon_remote ?: TRemoteDatasetIndex
  st_link ?: {
    data ?: {
      lt_link ?: ILinkPayload
      lt_link_params ?: string
      lt_target ?: boolean
      lt_text ?: string
      lt_title_text ?: string
      lt_icon ?: {
        fsType ?: string
        label ?: string
        identifier ?: string
      }
    }
  }
}

@Component({ name: 'StCtaToolbar' })
export default class StCtaToolbar extends FSXABaseSection<IPayload> {
  private defaultIconType : TIcon = 'light'

  async serverPrefetch () {
    await this.fetchRemoteDatasets()
  }

  async mounted () {
    // The watcher is required for an edge case where we have multiple icons being fetched on the page.
    // The first call will 'await' until the remote icon is fully fetched while the next call will skip the 'await'
    // and it would add the toolbar element without any icon.
    // Using @Watch for the following line causes a server client mismatch
    this.$watch('toolbarIconNameRemote', this.addToolbarElement, { immediate: true })
    await this.fetchRemoteDatasets()
  }

  private async fetchRemoteDatasets () : Promise<void> {
    await getOrFetchRemoteDatasets(this.fontawesomeIconRemoteDatasetIndex)
  }

  private addToolbarElement () : void {
    this.$store.commit('ToolbarElements/add', {
      href: this.toolbarLinkElements,
      iconName: this.toolbarIconNameRemote,
      iconType: this.defaultIconType,
      target: this.toolbarLinkTarget,
      text: this.toolbarTextElements,
      tooltipText: this.toolbarTooltipTextElements,
    })
  }

  private get toolbarIconNameRemote () : string {
    return getRemoteDatasetsFromStore(this.fontawesomeIconRemoteDatasetIndex)[0]?.data?.tt_fontawesome_v6_id || ''
  }

  private get fontawesomeIconRemoteDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.payload.st_fontawesome_icon_remote
  }

  private get toolbarLinkTarget () : boolean {
    return this.payload.st_link?.data?.lt_target || false
  }

  private get toolbarTextElements () : string {
    return this.payload.st_link?.data?.lt_text || ''
  }

  private get toolbarTooltipTextElements () : string {
    return this.payload.st_link?.data?.lt_title_text || ''
  }

  private get toolbarLinkElements () {
    const link = this.payload.st_link?.data?.lt_link
    const url = link?.referenceId ? this.getUrlByPageId(link.referenceId) : link

    return url + (this.payload.st_link?.data?.lt_link_params || '')
  }
}
